import { Button, Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageNotFoundContent } from './PageNotFoundStyle';

function PageNotFound() {
  const navigate = useNavigate();

  const handleHomePage = () => {
    navigate('/overview');
  };

  return (
    <Container xs={4}>
      <Grid container justifyContent="center">
        <Grid
          item
          style={{
            marginLeft: '200px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h1
            style={{
              marginTop: '70px',
              fontFamily: 'Helvetica Bold',
              color: '#002E5C',
            }}
          >
            PAGE NOT FOUND
          </h1>
          <PageNotFoundContent>
            Sorry, but the page you requested was not found on this server.
          </PageNotFoundContent>
          <br />
          <PageNotFoundContent>
            You might have mistyped the URL, or the page might have been moved from this location.
          </PageNotFoundContent>
          <br />
          <PageNotFoundContent>Thank You!</PageNotFoundContent>
          <br />
          <PageNotFoundContent>- The Ruffalo Noel Levitz website team</PageNotFoundContent>
          <br />
          <Button
            variant="contained"
            onClick={handleHomePage}
            style={{
              marginBottom: '53px',
              borderRadius: '21px',
            }}
          >
            Return Home
          </Button>
          <br />
        </Grid>
      </Grid>
    </Container>
  );
}

export default PageNotFound;
