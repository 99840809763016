import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const WelcomeMessage = styled(Typography)(() => ({
  marginLeft: '20px',
  fontFamily: 'Helvetica',
  fontWeight: 'bold',
  fontSize: 24,
  color: '#002E5C',
}));

export const LastUpdatedTimeStamp = styled(Typography)(() => ({
  fontFamily: 'Helvetica',
  fontSize: '12px',
  color: '#5C666F',
  paddingLeft: '5px',
  '&.MuiTypography-root': {
    fontFamily: 'inherit',
  },
}));

export const DashboardLabel = styled(Typography)(() => ({
  height: '24px',
  width: '136px',
  color: '#696F76',
  fontFamily: 'Helvetica Neue Regular',
  fontSize: '14px',
  letterSpacing: '0.25px',
  lineHeight: '16px',
  marginTop: '13px',
  paddingLeft: '18px',
  '&.MuiTypography-root': {
    fontFamily: 'inherit',
  },
}));
