/* eslint-disable import/prefer-default-export */
export const HEADER = {
  TITLE: 'RNL Dashboard',
  DASHBOARD: 'Dashboard',
  SYNC_CENTER: 'Sync Center',
  SMART_VIEW: 'Smart View',
  KNOWLEDGE_CENTER: 'Knowledge Center',
  SERVICE_NOW: 'Service Now',
  COLLABORATION_CENTER: 'Collaboration Center',
};

export const NOTIFICATIONS = {
  NOTIFICATIONS: 'Notifications',
  TYPE: 'Type',
  ALL: 'All',
  DISMISS: 'Dismiss',
  VIEW_ALL: 'View All',
  SECONDS_AGO: 'seconds ago',
  MINUTES_AGO: 'minutes ago',
};

export const DROPDOWN = {
  OVERVIEW: 'Overview',
  ENROLLEMENT: 'Enrollement',
  FUNDRAISING: 'Fundraising',
  STUDENT_SUCCESS: 'Student Success',
  SORT_BY: 'Sort By',
};

export const PERSONALIZATION = {
  EXECUTIVE: 'Executive',
  DAY_TO_DAY_OPERATIONS: 'Day-to-Day Operations',
  DATA_MOVER: 'Data Mover',
  SAVE: 'Save',
  ROLE: 'Whats your Primary Role',
  DESCRIPTION: 'Choose the description that best aligns with your current role below',
  WELCOME: 'Welcome Josh! Help us personalize your experience',
  EMAIL: 'Sunil.Hirole@ruffalonl.com',
  EMAIL1: 'dimitri_herman_5@hotmail.com',
  EMAIL2: 'jocelynst.pierre@outlook.com',
  FIRSTNAME: 'Sunil',
  LASTNAME: 'Hirole',
};

export const ALERT = {
  SUCCESS_TITLE: 'Sucess',
  SUCCESS_SUBTEXT: 'Personalization Choice:',
  ERROR_TITLE: 'Error',
  ERROR_SUBTEXT: 'Something Went Wrong!',
  TYPE: 'success',
};

export const OVERVIEW = {
  UPCOMING_TASKS: 'UPCOMING TASKS',
  VIEW_ALL: 'View All',
  UPLOAD_DATA: 'Upload Data',
  LAUNCH_WMJ: 'Launch WMJ',
  STUDENT_SUCCESS_STAT: 'Student Success Stat',
  ENAGEMENT_STAT: 'Enagement Stat',
  FUNDRAISING: 'Fundraising',
  LAUNCH_AREAS: 'LAUNCH AREAS',
  SMART_VIEW: 'SmartView',
  RNL_PRODUCT_CENTER: 'RNL Product Center',
  LAUNCH_SYNC_CENTER: 'Launch Sync Center',
  LAUNCH_SMARTVIEW: 'Launch RNL Smart View',
  LAUNCH_KNOWLEDGE_CENTER: 'Launch Knowledge Center',
  LAUNCH_RNL_SMART_VIEW: 'Launch RNL Smart View',
  LAUNCH_COLLABORATION_CENTER: 'Launch Collaboration Center',
  LAUNCH_RNL_PRODUCT_CENTER: 'Learn About RNL Products',
};

export const FOOTER = {
  TITLE: '1998-2022 Ruffalo Noel Levitz, LLC.All Rights Reserved.',
  TERMS_OF_USE: 'Terms of Use',
  PRIVACY_POLICY: 'Privacy Policy',
  SITE_MAP: 'Site Map',
};
