import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import NativeSelect from '@mui/material/NativeSelect';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Cell } from './SmartViewStyles';
import { saveSelectedReport } from '../../slices/powerBIExtIntSlice';
import Spinner from '../../components/Spinner';

const INTERNAL = 'internalReports';
const EXTERNAL = 'externalReports';

const portals = [
  { name: 'External Portal', value: 'externalReports' },
  { name: 'Internal Portal', value: 'internalReports' },
];

const SmartViewInternal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector((state) => state.powerBIExtInt.data?.internalPortalReports);

  const dataIInternal = useSelector(
    (state) => state.powerBIExtInt.data?.internalPortalReports?.internalReports,
  );

  const dataIExternal = useSelector(
    (state) => state.powerBIExtInt.data?.internalPortalReports?.externalReports,
  );

  const loading = useSelector((state) => state.powerBIExtInt.loading);

  const [selectedRow, setSelectedRow] = useState({});
  const [tableData, setTableData] = React.useState(dataIExternal);

  const [selected, setSelected] = React.useState(EXTERNAL);

  useEffect(() => {}, [selected]);

  const handleChange = (event) => {
    setSelected(event.target.value);
    if (event.target.value === EXTERNAL) {
      setTableData(dataIExternal);
    } else {
      setTableData(dataIInternal);
    }
  };

  const handleRowClick = (row) => {
    setSelectedRow(row);
    const selectedReportConfig = {
      groupId: row.groupId,
      reportId: row.reportId,
    };
    dispatch(saveSelectedReport(selectedReportConfig));
    navigate('/powerbi-report');
  };

  return (
    <Box>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <FormControl sx={{ width: 250, mt: 3 }}>
            <Select
              native
              sx={{ height: '35px' }}
              variant="outlined"
              inputProps={{ border: '1px solid red', height: '10px' }}
              value={selected}
              onChange={handleChange}
            >
              {portals.map((portal) => (
                <option key={portal.name} value={portal.value}>
                  {portal.name}
                </option>
              ))}
            </Select>
          </FormControl>

          {tableData && selected === EXTERNAL ? (
            <>
              <Grid container direction="row">
                {tableData?.enrollmentFunnel && tableData?.enrollmentFunnel?.length != 0 && (
                  <Grid item>
                    <TableContainer
                      sx={{
                        marginTop: '25px',
                        borderLeft: '1px solid #A9A9A9',
                        borderTop: '1px solid #A9A9A9',
                        borderBottom: '1px solid #A9A9A9',
                        height: '120px',
                        background: '#FFFFFF',
                      }}
                    >
                      <Table
                        sx={{ minWidth: 100, width: '210px' }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>Enrollment Funnel</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            {tableData?.enrollmentFunnel?.map((row) => (
                              <Grid item direction="column">
                                <Cell key={row.reportId} onClick={() => handleRowClick(row)}>
                                  {row.BIReportName}
                                </Cell>
                              </Grid>
                            ))}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}

                {tableData?.channelInsights && tableData?.channelInsights?.length != 0 && (
                  <Grid item>
                    <TableContainer
                      sx={{
                        marginTop: '25px',
                        borderTop: '1px solid #A9A9A9',
                        borderBottom: '1px solid #A9A9A9',
                        height: '120px',
                        background: '#FFFFFF',
                      }}
                    >
                      <Table
                        sx={{ minWidth: 100, width: '210px' }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>Channel Insights</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            {tableData?.channelInsights?.map((row) => (
                              <Grid container item direction="column">
                                <Cell key={row.reportId} onClick={() => handleRowClick(row)}>
                                  {row.BIReportName}
                                </Cell>
                              </Grid>
                            ))}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}

                {tableData?.advancedFinAid && tableData?.advancedFinAid?.length != 0 && (
                  <Grid item>
                    <TableContainer
                      sx={{
                        marginTop: '25px',
                        borderTop: '1px solid #A9A9A9',
                        borderBottom: '1px solid #A9A9A9',
                        height: '120px',
                        background: '#FFFFFF',
                      }}
                    >
                      <Table
                        sx={{ minWidth: 100, width: '210px' }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>Advance FinAid</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            {tableData.advancedFinAid.map((row) => (
                              <Grid container item direction="column">
                                <Cell key={row.reportId}>{row.BIReportName}</Cell>
                              </Grid>
                            ))}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}

                {tableData?.studentSuccess && tableData?.studentSuccess?.length != 0 && (
                  <Grid item>
                    <TableContainer
                      sx={{
                        marginTop: '25px',
                        height: '120px',
                        borderTop: '1px solid #A9A9A9',
                        borderBottom: '1px solid #A9A9A9',
                        background: '#FFFFFF',
                      }}
                    >
                      <Table
                        sx={{ minWidth: 100, width: '210px' }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>Student Success</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            {tableData.studentSuccess.map((row) => (
                              <Grid container item direction="column">
                                <Cell key={row.reportId}>{row.BIReportName}</Cell>
                              </Grid>
                            ))}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}

                {tableData?.graduateOnlineEnrollmentFunnel &&
                  tableData?.graduateOnlineEnrollmentFunnel?.length != 0 && (
                    <Grid item>
                      <TableContainer
                        sx={{
                          marginTop: '25px',
                          borderTop: '1px solid #A9A9A9',
                          borderBottom: '1px solid #A9A9A9',
                          borderRight: '1px solid #A9A9A9',
                          height: '120px',
                          background: '#FFFFFF',
                        }}
                      >
                        <Table
                          sx={{ minWidth: 100, width: '310px' }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold' }}>
                                Graduate & Online Enrollment Funnel
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              {tableData?.graduateOnlineEnrollmentFunnel?.map((row) => (
                                <Cell key={row.reportId}>{row.BIReportName}</Cell>
                              ))}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}
              </Grid>
            </>
          ) : (
            <>
              <TableContainer sx={{ width: '1500px' }}>
                <Grid container direction="row">
                  {tableData && tableData?.enrollmentFunnel?.length != 0 && (
                    <Grid item>
                      <TableContainer
                        sx={{
                          marginTop: '25px',
                          borderLeft: '1px solid #A9A9A9',
                          borderTop: '1px solid #A9A9A9',
                          borderBottom: '1px solid #A9A9A9',
                          height: '120px',
                          background: '#FFFFFF',
                        }}
                      >
                        <Table
                          sx={{ minWidth: 100, width: '210px' }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold' }}>Enrollment Funnel</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              {tableData?.enrollmentFunnel?.map((row) => (
                                <Grid item direction="column">
                                  <Cell key={row.reportId} onClick={() => handleRowClick(row)}>
                                    {row.BIReportName}
                                  </Cell>
                                </Grid>
                              ))}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}

                  {tableData && tableData?.channelInsights?.length != 0 && (
                    <Grid item>
                      <TableContainer
                        sx={{
                          marginTop: '25px',
                          borderTop: '1px solid #A9A9A9',
                          borderBottom: '1px solid #A9A9A9',
                          height: '120px',
                          background: '#FFFFFF',
                        }}
                      >
                        <Table
                          sx={{ minWidth: 100, width: '210px' }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold' }}>Channel Insights</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              {tableData?.channelInsights?.map((row) => (
                                <Grid item direction="column">
                                  <Cell key={row.reportId} onClick={() => handleRowClick(row)}>
                                    {row.childReportName}
                                  </Cell>
                                </Grid>
                              ))}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}

                  {tableData && tableData?.advancedFinAid?.length != 0 && (
                    <Grid item>
                      <TableContainer
                        sx={{
                          marginTop: '25px',
                          borderTop: '1px solid #A9A9A9',
                          borderBottom: '1px solid #A9A9A9',
                          height: '120px',
                          background: '#FFFFFF',
                        }}
                      >
                        <Table
                          sx={{ minWidth: 100, width: '210px' }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold' }}>Advanced FinAid</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              {tableData?.advancedFinAid?.map((row) => (
                                <Grid item direction="column">
                                  <Cell key={row.reportId} onClick={() => handleRowClick(row)}>
                                    {row.BIReportName}
                                  </Cell>
                                </Grid>
                              ))}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}

                  {tableData && tableData?.studentSuccess?.length != 0 && (
                    <Grid item>
                      <TableContainer
                        sx={{
                          marginTop: '25px',
                          borderTop: '1px solid #A9A9A9',
                          borderBottom: '1px solid #A9A9A9',
                          height: '120px',
                          background: '#FFFFFF',
                        }}
                      >
                        <Table
                          sx={{ minWidth: 100, width: '210px' }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold' }}>student Success</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              {tableData?.studentSuccess?.map((row) => (
                                <Grid item direction="column">
                                  <Cell key={row.reportId} onClick={() => handleRowClick(row)}>
                                    {row.BIReportName}
                                  </Cell>
                                </Grid>
                              ))}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}

                  {tableData && tableData?.graduateOnlineEnrollmentFunnel?.length != 0 && (
                    <Grid item>
                      <TableContainer
                        sx={{
                          marginTop: '25px',
                          borderTop: '1px solid #A9A9A9',
                          borderBottom: '1px solid #A9A9A9',
                          borderRight: '1px solid #A9A9A9',
                          height: '120px',
                          background: '#FFFFFF',
                        }}
                      >
                        <Table
                          sx={{ minWidth: 100, width: '310px' }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold' }}>
                                Graduate & Online Enrollment Funnel
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              {tableData?.graduateOnlineEnrollmentFunnel?.map((row) => (
                                <Cell key={row.reportId}>{row.BIReportName}</Cell>
                              ))}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}
                </Grid>
              </TableContainer>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default SmartViewInternal;
