import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  useMsal,
  useIsAuthenticated,
} from '@azure/msal-react';
import Enrollment from '../pages/Enrollment';
import Studentsuccess from '../pages/Studentsuccess';
import NavBar from '../components/Navigation/NavBar';
import PowerBIReport from '../pages/PowerBIReport';
import PageNotFound from '../pages/PageNotFound';
import SmartView from '../pages/SmartView/SmartView';
import GenericAlert from '../components/Alert';
import AdvancedFinaid from '../pages/AdvancedFinaid';
import OverviewDashboard from '../pages/Home/Overview/OverviewDashboard';
import SSORedirect from '../components/SSORedirect';
import Home from '../pages/Home'

function RouteComponent() {
  const isAuthenticated = useIsAuthenticated();
  return (
    <Routes>
      <Route path="/" exact element={<NavBar />}>
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Navigate replace to="/overview" />
            ) : (
              <Navigate replace to="/student-success" />
            )
          }
        />
        {/* <Route
          path="/"
          element={<Home />}
        /> */}
        <Route path="/overview" element={<OverviewDashboard />} />
        <Route path="/enrollment" element={<Enrollment />} />
        <Route path="/student-success" element={<Studentsuccess />} />
        <Route path="/powerbi-report" element={<PowerBIReport />} />
        <Route path="/smartview" element={<SmartView />} />
        <Route path="/advanced-finaid" element={<AdvancedFinaid />} />
        <Route path="/alert" element={GenericAlert} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/sso-sse-redirect" element={<SSORedirect />} />
      </Route>
    </Routes>
  );
}
export default RouteComponent;
