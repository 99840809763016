import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userSubscribed: true,
  subscribedToEnrollment: false,
  subscribedToStudentSuccess: false,
  subscribedToFundraising: false,
};

const productTypeSlice = createSlice({
  name: 'productType',
  initialState,
  reducers: {
    getProductType(state, action) {
      state.push({
        ...state,
        subscribedToEnrollment: action.payload.subscribedToEnrollment,
        subscribedToStudentSuccess: action.payload.subscribedToStudentSuccess,
        subscribedToFundraising: action.payload.subscribedToFundraising,
      });
    },
  },
});

export const { getProductType } = productTypeSlice.actions;
export default productTypeSlice;
