import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { fetchPowerBIEmbeddedConfig } from '../../../slices/powerBISlice';
import './PowerBI.css';
import Decrypt from '../../../utils/Decrypt';
import { Typography } from '@mui/material';

export default function PowerBIContainer() {
  const dispatch = useDispatch();
  const selectedReportName = useSelector((state) => state.powerBIExtInt.selectedCell);
  const config = useSelector((state) => state.powerBI);
  const error = useSelector((state) => state.powerBI?.data?.status);
  const powerBIFilter = selectedReportName?.powerBIFilter;
  const embeddedToken = config.embeddedToken ? Decrypt(config.embeddedToken) : '';

  const initFetch = useCallback(() => {
    const body = {
      groupId: selectedReportName.groupId,
      reportId: selectedReportName.reportId,
    };
    dispatch(fetchPowerBIEmbeddedConfig(body));
  }, [dispatch]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  return (
    <>
      {error && error !== 200 ? (
        <Typography
          sx={{
            color: 'red',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '150px',
            marginBottom: '150px',
          }}
        >
          PowerBI Report unavailable
        </Typography>
      ) : (
        <div className="PowerBIApp">
          <header className="PowerBIApp-header">
            <PowerBIEmbed
              embedConfig={{
                type: 'report', // Supported types: report, dashboard, tile, visual and qna
                id: config.reportId,
                embedUrl: config.embedURL,
                accessToken: embeddedToken,
                tokenType: models.TokenType.Embed,
                viewMode: models.ViewMode.View,
                filters: powerBIFilter,
                // filters: [
                //   {
                //     $schema: 'http://powerbi.com/product/schema#basic',
                //     target: {
                //       table: 'BI_Contact_Customer',
                //       column: 'ContactId',
                //     },
                //     operator: 'eq',
                //     values: ['D44644A0-8396-E911-A985-000D3A062608'],
                //   },
                // ],
                settings: {
                  layoutType: models.LayoutType.Custom,
                  customLayout: {
                    displayOption: models.DisplayOption.FitToPage,
                  },
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false,
                    },
                    pageNavigation: {
                      visible: true,
                    },
                  },
                  background: models.BackgroundType.Transparent,
                },
              }}
              eventHandlers={
                new Map([
                  [
                    'loaded',
                    function () {
                      console.log('Report loaded');
                    },
                  ],
                  [
                    'rendered',
                    function () {
                      console.log('Report rendered');
                    },
                  ],
                  [
                    'error',
                    function (event) {
                      console.log('Report error', event.detail);
                    },
                  ],
                ])
              }
              cssClassName="report-style-class"
              getEmbeddedComponent={(embeddedReport) => {
                window.report = embeddedReport;
              }}
            />
          </header>
        </div>
      )}
    </>
  );
}
