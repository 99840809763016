import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GetKPIValuesService from '../services/GetKPIValuesService';

const initialState = {
  data: {},
  demandLoading: false,
};

export const fetchDemandBuilderKPIValues = createAsyncThunk(
  'demandBuilderKpi/fetchDemandBuilderKPIValues',
  async (email) => {
    const response = await GetKPIValuesService.getDemandBuilderValues(email);
    return response;
  },
);

const demandBuilderSlice = createSlice({
  name: 'demandBuilderKpi',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchDemandBuilderKPIValues.pending]: (state) => {
      state.demandLoading = true;
    },
    [fetchDemandBuilderKPIValues.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.demandLoading = false;
    },
    [fetchDemandBuilderKPIValues.rejected]: (state) => {
      state.demandLoading = false;
    },
  },
});

export default demandBuilderSlice.reducer;
