/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CopyrightIcon from '@mui/icons-material/Copyright';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import IconButton from '@mui/material/IconButton';
import { FooterDetails, FooterLink, FooterDivider } from './FooterStyles';

const Footer = () => {
  return (
    <Box
      sx={{
        height: '93px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid #D3D3D3',
      }}
    >
      <CopyrightIcon
        sx={{
          color: '#89949E',
          height: '15px',
          width: '15px',
          marginLeft: '22px',
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      />
      <FooterDetails>1998-2022 Ruffalo Noel Levitz, LLC.All Rights Reserved.</FooterDetails>

      <FooterLink
        underline="none"
        sx={{ paddingLeft: '400px' }}
        href="https://www.ruffalonl.com/terms-of-use/"
        target="_blank"
      >
        Terms of Use
      </FooterLink>
      <FooterDivider
        variant="middle"
        flexItem
        sx={{ borderLeft: '2px solid #E9EAEB', height: '30px', width: '1px', marginTop: '31px' }}
      />
      <FooterLink
        underline="none"
        sx={{ padding: '0px !important' }}
        href="https://www.ruffalonl.com/privacy-policy/"
        target="_blank"
      >
        Privacy Policy
      </FooterLink>
      <FooterDivider
        variant="middle"
        flexItem
        sx={{ borderLeft: '2px solid #E9EAEB', height: '30px', width: '1px', marginTop: '31px' }}
      />
      <IconButton name="facebook" href="https://www.facebook.com/RuffaloNL/" target="_blank">
        <FacebookIcon sx={{ color: '#89949E', cursor: 'pointer' }} />
      </IconButton>
      <IconButton name="twitter" href="https://twitter.com/RuffaloNLEM/" target="_blank">
        {' '}
        <TwitterIcon sx={{ color: '#89949E', cursor: 'pointer' }} />
      </IconButton>
      <IconButton
        name="linkedIn"
        href="https://www.linkedin.com/company/ruffalonl/"
        target="_blank"
      >
        {' '}
        <LinkedInIcon sx={{ color: '#89949E', cursor: 'pointer' }} />
      </IconButton>
    </Box>
  );
};

export default Footer;
