/* eslint-disable react/jsx-no-undef */
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import SmartViewInternal from './SmartViewInternal';
import {
  fetchSmartViewConfig,
  saveSelectedReport,
  fetchPowerBIExtIntReport,
} from '../../slices/powerBIExtIntSlice';
import { Cell } from './SmartViewStyles';
import MockPowerBIReport from '../../MockData/MockPowerBIReport';
import Spinner from '../../components/Spinner';

function SmartViewTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instance } = useMsal();
  let activeAccount;
  let email;

  if (instance) {
    activeAccount = instance.getActiveAccount();
    email = activeAccount.idTokenClaims.tableau_email;
  }

  const advancedFinAid = useSelector(
    (state) => state.powerBIExtInt.data.externalPortalReports?.externalReports?.advancedFinAid,
  );

  const studentSuccess = useSelector(
    (state) => state.powerBIExtInt.data.externalPortalReports?.externalReports?.studentSuccess,
  );

  const channelInsights = useSelector(
    (state) => state.powerBIExtInt.data.externalPortalReports?.externalReports?.channelInsights,
  );
  let enrollmentFunnel = useSelector(
    (state) => state.powerBIExtInt.data.externalPortalReports?.externalReports?.enrollmentFunnel,
  );
  const graduateOnlineEnrollmentFunnel = useSelector(
    (state) =>
      state.powerBIExtInt.data.externalPortalReports?.externalReports
        ?.graduateOnlineEnrollmentFunnel,
  );
  enrollmentFunnel = [
    {
      contactName: 'Sunil Hirole',
      BIReportName: 'Applicant Cultivator',
      childReportName: null,
      pageNumber: 'PGE-000214',
      groupId: '08303b57-2021-4afc-8846-c8f241482209',
      reportId: '3245ee43-4a2f-44b8-9c3a-9d8cd36781ff',
      reportName: 'Applicant Cultivator',
    },
  ];

  const others = useSelector(
    (state) => state.powerBIExtInt.data.externalPortalReports?.externalReports?.others,
  );
  const portal = useSelector((state) => state.powerBIExtInt.data.externalPortalReports?.portalName);
  const loading = useSelector((state) => state.powerBIExtInt.loading);
  const data = useSelector((state) => state.powerBIExtInt.data);
  const message = useSelector((state) => state.powerBIExtInt.data?.message);

  const error = useSelector((state) => state.powerBIExtInt?.data?.data?.status);

  const [selectedRow, setSelectedRow] = useState({});

  const initFetch = useCallback(() => {
    dispatch(fetchPowerBIExtIntReport(email));
  }, [dispatch]);

  useEffect(() => {
    if (_.isEmpty(data)) {
      initFetch();
    }
  }, [initFetch, dispatch]);

  const handleRowClick = (row) => {
    setSelectedRow(row);
    const selectedReportConfig = {
      groupId: row.groupId,
      reportId: row.reportId,
      powerBIFilter: row.powerBIFilter,
    };

    dispatch(saveSelectedReport(selectedReportConfig));
    navigate('/powerbi-report');
  };

  const showDropdown =
    portal !== 'External Portal' ? (
      message ? (
        <Typography
          sx={{ marginTop: '20px', display: 'flex', justifyContent: 'center', color: 'red' }}
        >
          {message}
        </Typography>
      ) : (
        <SmartViewInternal />
      )
    ) : (
      ''
    );

  return (
    <Box sx={{ marginTop: '25px', marginLeft: '25px', marginRight: '25px' }}>
      <MockPowerBIReport />
      {error ? (
        <Typography
          sx={{ marginTop: '20px', display: 'flex', justifyContent: 'center', color: 'red' }}
        >
          No data to display
        </Typography>
      ) : (
        <>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {showDropdown}
              <TableContainer sx={{ width: 'auto' }}>
                <Grid container direction="row">
                  {enrollmentFunnel && (
                    <Grid item>
                      <TableContainer
                        sx={{
                          marginTop: '25px',
                          borderLeft: '1px solid #A9A9A9',
                          borderTop: '1px solid #A9A9A9',
                          borderBottom: '1px solid #A9A9A9',
                          height: '500px',
                          background: '#FFFFFF',
                        }}
                      >
                        <Table
                          sx={{ minWidth: 100, width: '267px' }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold' }}>Enrollment Funnel</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              {enrollmentFunnel.map((row) => (
                                // <Grid container item direction="column">
                                <Cell
                                  key={row.reportId}
                                  onClick={() => handleRowClick(row)}
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  {row.childReportName ? row.childReportName : row.BIReportName}
                                </Cell>
                                // </Grid>
                              ))}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}

                  {channelInsights && (
                    <Grid item>
                      <TableContainer
                        sx={{
                          marginTop: '25px',
                          borderTop: '1px solid #A9A9A9',
                          borderBottom: '1px solid #A9A9A9',
                          height: '500px',
                          background: '#FFFFFF',
                        }}
                      >
                        <Table
                          sx={{ minWidth: 100, width: '295px' }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold' }}>Channel Insights</TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {channelInsights.map((row) => (
                              <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                key={row.reportId}
                              >
                                {/* <Grid container item direction="column" key={row.reportId}> */}
                                <Cell
                                  key={row.reportId}
                                  onClick={() => handleRowClick(row)}
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  {row.childReportName ? row.childReportName : row.BIReportName}
                                </Cell>
                                {/* </Grid> */}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}

                  {/* {advancedFinAid && advancedFinAid.length != 0 && (
                    <Grid item>
                      <TableContainer
                        sx={{
                          marginTop: '25px',
                          borderTop: '1px solid #A9A9A9',
                          borderBottom: '1px solid #A9A9A9',
                          height: '500px',
                          background: '#FFFFFF',
                        }}
                      >
                        <Table
                          sx={{ minWidth: 100, width: '210px' }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold' }}>Advance FinAid</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              {advancedFinAid.map((row) => (
                                <Grid container item direction="column">
                                  <Cell key={row.reportId} onClick={() => handleRowClick(row)}>
                                    {row.BIReportName}
                                  </Cell>
                                </Grid>
                              ))}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )} */}

                  {studentSuccess && studentSuccess.length !== 0 && (
                    <Grid item>
                      <TableContainer
                        sx={{
                          marginTop: '25px',
                          height: '500px',
                          borderTop: '1px solid #A9A9A9',
                          borderBottom: '1px solid #A9A9A9',
                          background: '#FFFFFF',
                        }}
                      >
                        <Table
                          sx={{ minWidth: 100, width: '210px' }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold' }}>Student Success</TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {studentSuccess.map((row) => (
                              <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                key={row.reportId}
                              >
                                {/* <Grid container item direction="column" key={row.reportId}> */}
                                <Cell
                                  key={row.reportId}
                                  onClick={() => handleRowClick(row)}
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  {row.childReportName ? row.childReportName : row.BIReportName}
                                </Cell>
                                {/* </Grid> */}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}

                  {graduateOnlineEnrollmentFunnel && (
                    <Grid item>
                      <TableContainer
                        sx={{
                          marginTop: '25px',
                          height: '500px',
                          borderTop: '1px solid #A9A9A9',
                          borderBottom: '1px solid #A9A9A9',
                          background: '#FFFFFF',
                        }}
                      >
                        <Table
                          sx={{ minWidth: 100, width: '320px' }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold' }}>
                                Graduate & Online Enrollment Funnel
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {graduateOnlineEnrollmentFunnel.map((row) => (
                              <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                key={row.reportId}
                              >
                                <Cell key={row.reportId} onClick={() => handleRowClick(row)}>
                                  {row.childReportName ? row.childReportName : row.BIReportName}
                                </Cell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}

                  {others && others.length !== 0 && (
                    <Grid item>
                      <TableContainer
                        sx={{
                          marginTop: '25px',
                          borderTop: '1px solid #A9A9A9',
                          borderBottom: '1px solid #A9A9A9',
                          borderRight: '1px solid #A9A9A9',
                          height: '500px',
                          background: '#FFFFFF',
                        }}
                      >
                        <Table
                          sx={{ minWidth: 100, width: '261px' }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                Others
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              {others.map((row) => (
                                // <Grid container item direction="column">
                                <Cell
                                  key={row.reportId}
                                  onClick={() => handleRowClick(row)}
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  {row.childReportName ? row.childReportName : row.BIReportName}
                                </Cell>
                                // </Grid>
                              ))}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}
                </Grid>
              </TableContainer>
            </>
          )}
        </>
      )}
    </Box>
  );
}

export default SmartViewTable;
