import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GetKPIValuesService from '../services/GetKPIValuesService';

export const KPILoadError = (rootState) => KPIsState(rootState).KPIerror;

const initialState = {
  data: {},
  KPIloading: false,
};

export const fetchForecastPlus = createAsyncThunk(
  'forecastPlus/fetchForecastPlus',
  async (email) => {
    const response = await GetKPIValuesService.getForecastPlus(email);
    return response;
  },
);

const forecastPlusSlice = createSlice({
  name: 'forecastPlus',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchForecastPlus.pending]: (state) => {
      state.KPIloading = true;
    },
    [fetchForecastPlus.fulfilled]: (state, action) => {
      state.data = action.payload;

      state.KPIloading = false;
    },
    [fetchForecastPlus.rejected]: (state) => {
      state.KPIloading = false;
      state.KPIerror = 'Error, something went wrong. Contact support if problem persist';
    },
  },
});

export default forecastPlusSlice.reducer;
