/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-array-index-key */
import { FormControl, Grid, MenuItem, Select } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DropdownIcon } from './DropdownStyles';

const options = ['Enrollment','Advanced FinAid', 'Student Success'];

const Dropdown = () => {
  const data = useSelector((state) => state.productType);
  const location = useLocation();
  const navigate = useNavigate();
  const initialType = location.pathname !== '/enrollment' ? localStorage.getItem('type') : 'Enrollment';
  const [type, setType] = useState(initialType);

  const onHandleChange = (event) => {
    localStorage.setItem('type', event.target.value);
    setType(event.target.value);
    const dropDownValue = event.target.value;

    if (dropDownValue === 'Overview' && data.userSubscribed) {
      navigate('/overview');
    }

    if (dropDownValue === 'Overview' && !data.userSubscribed) {
      navigate('/student-success');
    }

    if (dropDownValue === 'Enrollment' && data.userSubscribed) {
      navigate('/enrollment');
    }

    if (dropDownValue === 'Advanced FinAid' && data.userSubscribed) {
      navigate('/advanced-finaid');
    }

    if (dropDownValue === 'Student Success' && data.userSubscribed) {
      navigate('/student-success');
    }

  };

  return (
    <Grid container display="flex" justifyContent="flex-end">
      <Grid item>
        <FormControl sx={{ mr: 5, mt: 2 }}>
          <Select
            id="product-type"
            sx={{ width: 209, height: 35 }}
            onChange={onHandleChange}
            value={type === '' || type === undefined ? 'overview' : type}
            defaultValue="overview"
          >
            {options.map((option, index) => (
              <MenuItem value={option} key={index}>
                <Grid container>
                  <DropdownIcon>
                    <DashboardIcon />
                  </DropdownIcon>
                  <Grid item sx={{ mt: 0.2 }}>
                    {option}
                  </Grid>
                </Grid>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default Dropdown;
