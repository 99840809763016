import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GetKPIValuesService from '../services/GetKPIValuesService';

const initialState = {
  data: {},
  loading: false,
  error: '',
};

export const fetchKPIDetails = createAsyncThunk('kpiDetails/fetchKPIDetails', async (email) => {
  const response = await GetKPIValuesService.getKPIDetails(email);
  return response;
});

const kpiSlice = createSlice({
  name: 'kpiDetails',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchKPIDetails.pending]: (state) => {
      state.loading = true;
    },
    [fetchKPIDetails.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchKPIDetails.rejected]: (state) => {
      state.loading = false;
      state.error = 'Error, something went wrong. Contact support if problem persist';
    },
  },
});

export default kpiSlice.reducer;
