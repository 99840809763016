import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import DialogTitle from '@mui/material/DialogTitle';

export const PersonalizeDialogTitle = styled(DialogTitle)(() => ({
  color: '#002E5C',
  fontFamily: 'Helvetica',
  fontSize: '24px',
  fontWeight: 'bold',
  letterSpacing: 0,
  lineHeight: '20px',
  textAlign: 'center',
  '&.MuiTypography-root': {
    fontFamily: 'inherit',
  },
}));

export const PersonalizeDialogSubTitle = styled(Typography)(() => ({
  color: '#5C666F',
  fontFamily: 'Helvetica Neue',
  fontSize: '16px',
  letterSpacing: '0',
  lineHeight: '24px',
  textAlign: 'center',
  paddingLeft: '120px',
  paddingRight: '102px',
  '&.MuiTypography-root': {
    fontFamily: 'inherit',
  },
}));

export const CardTemplate = styled(Card)(() => ({
  height: '210px',
  width: '255px',
  borderRadius: '3px',
  backgroundColor: '#FFFFFF',
  boxShadow: '0 0 3px 0 rgba(0,0,0,0.35)',
  '&:hover': {
    border: '1.5px solid #A9A9A9',
    borderRadius: '3px',
    cursor: 'pointer',
  },
}));

export const RoleTitle = styled(Typography)(() => ({
  color: '#002E5C',
  fontFamily: 'Helvetica',
  fontSize: '16px',
  fontWeight: 'bold',
  textAlign: 'center',
}));

export const RoleDetails = styled(Typography)(() => ({
  color: '#2D3136',
  fontFamily: 'Helvetica Neue',
  fontSize: '14px',
  '&.MuiTypography-root': {
    fontFamily: 'inherit',
  },
}));
