import Grid from '@mui/material/Grid';

import Divider from '@mui/material/Grid';
import Welcome from '../Welcome';


const Header = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Welcome />
        </Grid>
        <Grid item xs={6} sx={{ mt: 5, pl: 70 }}>
        
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2 }} />
    </>
  );
};

export default Header;
