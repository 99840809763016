import api from '../utils/axios';
import { TEST_SMARTVIEW_CONFIG } from '../utils/Constants';

// eslint-disable-next-line max-len
const getSmartViewConfig = () =>
  api.get(TEST_SMARTVIEW_CONFIG, {}, { email: 'Sunil.Hirole@ruffalonl.com' });

const SmartViewConfigService = {
  getSmartViewConfig,
};
export default SmartViewConfigService;
