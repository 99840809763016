import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GetKPIValuesService from '../services/GetKPIValuesService';

export const KPILoadError = (rootState) => KPIsState(rootState).KPIerror;

const initialState = {
  data: {},
  funnelData: {},
  KPIloading: false,
};

export const fetchKPIValues = createAsyncThunk(
  'engagementScoreKpi/fetchKPIValues',
  async (email) => {
    const response = await GetKPIValuesService.getEngagementScore(email);
    return response;
  },
);

const engagementScoreSlice = createSlice({
  name: 'engagementScoreKpi',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchKPIValues.pending]: (state) => {
      state.KPIloading = true;
    },
    [fetchKPIValues.fulfilled]: (state, action) => {
      state.data = action.payload.EngagementScore;
      state.funnelData = action.payload.funnelStage;
      state.KPIloading = false;
    },
    [fetchKPIValues.rejected]: (state) => {
      state.KPIloading = false;
      state.KPIerror = 'Error, something went wrong. Contact support if problem persist';
    },
  },
});

export default engagementScoreSlice.reducer;
