/* eslint-disable max-len */
import api from '../utils/axios';
import {
  GET_ENGAGEMENT_SCORES,
  GET_DEMAND_BUILDER,
  GET_FORECAST_SCORE,
  GET_KPI_DETAILS,
} from '../utils/Constants';
import { PERSONALIZATION } from '../utils/Labels';

// const getEngagementScore = (email) =>
//   api.get(GET_ENGAGEMENT_SCORES, {}, { email: PERSONALIZATION.EMAIL1 });

// const getDemandBuilderValues = (email) =>
//   api.get(GET_DEMAND_BUILDER, {}, { email: PERSONALIZATION.EMAIL1 });

// const getForecastPlus = (email) =>
//   api.get(GET_FORECAST_SCORE, {}, { email: PERSONALIZATION.EMAIL2 });

const getKPIDetails = (email) =>
  api.get(GET_KPI_DETAILS, {}, { email: 'dimitri_herman_5@hotmail.com' });

const GetSSEKPIValuesService = {
  //  getEngagementScore,
  // getDemandBuilderValues,
  // getForecastPlus,
  getKPIDetails,
};
export default GetSSEKPIValuesService;
