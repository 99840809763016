import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

const UserRoleAlert = React.forwardRef((props, ref) => (
  <Alert elevation={1} ref={ref} variant="filled" {...props} />
));

export default function GenericAlert() {
  const [userRoleOpenAlert, setUserRoleOpenAlert] = useState(false);
  const alertData = useSelector((state) => state.genericAlert);
  const severity = alertData.alertType === 'success' ? 'success' : 'warning';

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUserRoleOpenAlert(false);
  };

  useEffect(() => {
    setUserRoleOpenAlert(alertData.openAlert);
  }, [alertData.openAlert]);

  return (
    <div>
      <Snackbar
        open={userRoleOpenAlert}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <UserRoleAlert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }}>
          {alertData.alertTitle}!<p> {alertData.alertMessage}</p>
        </UserRoleAlert>
      </Snackbar>
    </div>
  );
}
