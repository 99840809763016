/* eslint-disable max-len */
import api from '../utils/axios';
import { GET_PRODUCT_CARDS } from '../utils/Constants';

const getProductCards = (ProductCategory) =>
  api.get(GET_PRODUCT_CARDS, { category: ProductCategory }, {});

const ProductCardsService = {
  getProductCards,
};
export default ProductCardsService;
