import * as React from 'react';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import PeopleIcon from '@mui/icons-material/People';
import InfoIcon from '@mui/icons-material/Info';
import SchoolIcon from '@mui/icons-material/School';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CachedIcon from '@mui/icons-material/Cached';
import { CardContent, Card } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';
import {
  StatLabels,
  OverviewLabels,
  PercentMain,
  PercentSub,
  PlusSymbol,
  LaunchLabels,
  LaunchDetails,
  LaunchLink,
  RNLLaunchLink,
} from '../../pages/Home/Overview/OverviewStyles';
import { OVERVIEW } from '../../utils/Labels';
import FunnelChart from '../Charts/FunnelChart';
import Funnel from '../Charts/Funnel';
import rnlLogo from '../../assets/Images/RNL_gray_logo.png';
import { fetchKPIValues } from '../../slices/engagementScoreSlice';
import { fetchDemandBuilderKPIValues } from '../../slices/demandBuilderSlice';
import NumberConversion from '../../utils/NumberConversion';
import Spinner, { SpinnerDemandBuilder } from '../Spinner';
import { fetchForecastPlus } from '../../slices/forecastPlusSlice';
import { fetchKPIDetails } from '../../slices/kpiSlice';
import {
  KNOWLEDGE_CENTER_URL,
  COLLABORATION_CENTER_URL,
  SYNC_CENTER_URL,
} from '../../utils/Constants';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Accordion = styled((props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

// export default function CustomizedAccordions() {
//   const { instance } = useMsal();
//     let activeAccount;
//     let email;

//     if (instance) {
//         activeAccount = instance.getActiveAccount();
//         console.log('=====activeAccount====',activeAccount)
//         email = activeAccount.idTokenClaims.tableau_email
//     }
//   const dispatch = useDispatch();
//   const kpiDetails = useSelector((state) => state.engagementScoreKpi.data);
//   const loading = useSelector((state) => state.engagementScoreKpi.KPIloading);
//   const students = kpiDetails.numberOfStudent;
//   const noOfStudent = NumberConversion(students);
//   const average = kpiDetails.average ? kpiDetails.average.toFixed(2) : '';
//   const {percentage} = kpiDetails;

//   const search = useSelector((state) => state.demandBuilderKpi.data.search);
//   const applicants = useSelector((state) => state.demandBuilderKpi.data.applicants);
//   const inquiries = useSelector((state) => state.demandBuilderKpi.data.inquiries);
//   const loading = useSelector((state) => state.demandBuilderKpi.loading);
//   const forecastPlus = useSelector((state) => state.forecastPlus.data);

//   //   const funnelData = useSelector((state) => state.engagementScoreKpi.funnelData);
//   //   console.log('funnelData', funnelData);

//   const [expanded, setExpanded] = React.useState('panel1');

//   const handleChange = (panel) => (event, newExpanded) => {
//     setExpanded(newExpanded ? panel : false);
//   };

//   const initFetch = useCallback(() => {
//     dispatch(fetchForecastPlus(email));
//     dispatch(fetchKPIValues());
//     dispatch(fetchDemandBuilderKPIValues());
//   }, [dispatch]);
//   useEffect(() => {
//     initFetch();
//   }, [initFetch]);

//   return (
//     <div>
//       <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
//         <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
//           <Typography sx={{ color: '#002E5C', fontFamily: 'Helvetica', fontSize: '14px' }}>
//             <b>Student Search & Engagement - Overview</b>
//           </Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Box
//             sx={{
//               backgroundColor: '#FFFFFF',
//               paddingLeft: '20px',
//               paddingTop: '10px',
//               paddingBottom: '10px',
//               border: '1px solid rgba(0, 0, 0, .125)',
//             }}
//           >
//             <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
//               <Grid item xs={6}>
//                 <OverviewLabels sx={{ paddingBottom: '10px' }}>CONVERSION</OverviewLabels>
//                 <Paper
//                   sx={{
//                     height: '120px',
//                     borderRadius: '3px',
//                     backgroundColor: '#FFFFFF',
//                     boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
//                     marginBottom: '15px',
//                   }}
//                 >
//                   <Grid
//                     container
//                     direction="row"
//                     sx={{
//                       paddingTop: '30px',
//                       paddingLeft: '16px',
//                       display: 'flex',
//                       justifyContent: 'center',
//                       alignItems: 'center',
//                     }}
//                   >
//                     <Grid item direction="column">
//                       <Grid item direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
//                         <PercentMain>3%</PercentMain>
//                         <ArrowDropUpIcon sx={{ cursor: 'pointer', color: '#028340' }} />
//                         <PlusSymbol sx={{ color: '#028340' }} variant="body2">
//                           +
//                         </PlusSymbol>
//                         <PercentSub
//                           sx={{
//                             color: '#028340',
//                           }}
//                           variant="body2"
//                         >
//                           10%
//                         </PercentSub>
//                       </Grid>
//                       <Grid item>
//                         <StatLabels>Searched</StatLabels>
//                       </Grid>
//                     </Grid>
//                     <Divider
//                       sx={{
//                         boxSizing: 'border-box',
//                         height: '68px',
//                         width: '2px',
//                         borderLeft: '2px solid #E9EAEB',
//                       }}
//                       variant="middle"
//                       flexItem
//                     />
//                     <Grid item direction="column" sx={{ paddingLeft: '20px' }}>
//                       <Grid item direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
//                         <PercentMain sx={{ color: '#002E5C' }}>5%</PercentMain>
//                         <ArrowDropUpIcon sx={{ cursor: 'pointer', color: '#028340' }} />
//                         <PlusSymbol sx={{ color: '#028340' }} variant="body2">
//                           +
//                         </PlusSymbol>
//                         <PercentSub
//                           sx={{
//                             color: '#028340',
//                           }}
//                           variant="body2"
//                         >
//                           10%
//                         </PercentSub>
//                       </Grid>
//                       <Grid item>
//                         <StatLabels>Responders</StatLabels>
//                       </Grid>
//                     </Grid>
//                     <Divider
//                       sx={{
//                         boxSizing: 'border-box',
//                         height: '68px',
//                         width: '2px',
//                         borderLeft: '2px solid #E9EAEB',
//                       }}
//                       variant="middle"
//                       flexItem
//                     />
//                     <Grid item direction="column" sx={{ paddingLeft: '20px' }}>
//                       <Grid item direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
//                         <PercentMain
//                           sx={{
//                             color: '#002E5C',
//                           }}
//                         >
//                           2%
//                         </PercentMain>
//                         <ArrowDropUpIcon
//                           sx={{
//                             cursor: 'pointer',
//                             color: ' #C73A32',
//                             transform: 'rotate(-180deg)',
//                           }}
//                         />
//                         <PlusSymbol sx={{ color: '#C73A32' }} variant="body2">
//                           +
//                         </PlusSymbol>
//                         <PercentSub
//                           sx={{
//                             color: '#C73A32',
//                           }}
//                           variant="body2"
//                         >
//                           10%
//                         </PercentSub>
//                       </Grid>
//                       <Grid item>
//                         <StatLabels>Response Rate</StatLabels>
//                       </Grid>
//                     </Grid>
//                   </Grid>
//                 </Paper>

//                 <OverviewLabels sx={{ paddingBottom: '10px' }}>FORECAST PLUS</OverviewLabels>
//                 {loading ? (
//                   <Spinner />
//                 ) : (
//                   <Paper
//                     sx={{
//                       height: '120px',
//                       // width: '550px',
//                       borderRadius: '3px',
//                       backgroundColor: '#FFFFFF',
//                       boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
//                       marginBottom: '15px',
//                     }}
//                   >
//                     <Grid
//                       container
//                       direction="row"
//                       sx={{
//                         paddingTop: '30px',
//                         paddingLeft: '16px',
//                         display: 'flex',
//                         alignItems: 'center',
//                         justifyContent: 'center',
//                       }}
//                     >
//                       <Grid item direction="column">
//                         <Grid item direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
//                           <PercentMain>{forecastPlus.projection}</PercentMain>
//                         </Grid>
//                         <Grid item>
//                           <StatLabels>Projection</StatLabels>
//                         </Grid>
//                       </Grid>
//                       <Divider
//                         sx={{
//                           boxSizing: 'border-box',
//                           height: '68px',
//                           width: '2px',
//                           borderLeft: '2px solid #E9EAEB',
//                         }}
//                         variant="middle"
//                         flexItem
//                       />
//                       <Grid item direction="column" sx={{ paddingLeft: '20px' }}>
//                         <Grid item direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
//                           <PercentMain sx={{ color: '#002E5C' }}>{forecastPlus.actual}</PercentMain>
//                         </Grid>
//                         <Grid item>
//                           <StatLabels>Actual</StatLabels>
//                         </Grid>
//                       </Grid>
//                       <Divider
//                         sx={{
//                           boxSizing: 'border-box',
//                           height: '68px',
//                           width: '2px',
//                           borderLeft: '2px solid #E9EAEB',
//                         }}
//                         variant="middle"
//                         flexItem
//                       />
//                       <Grid item direction="column" sx={{ paddingLeft: '20px' }}>
//                         <Grid item direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
//                           <PercentMain
//                             sx={{
//                               color: '#002E5C',
//                             }}
//                           >
//                             {forecastPlus.forecastPercentage}%
//                           </PercentMain>
//                         </Grid>
//                         <Grid item>
//                           <StatLabels>Actual / Projection</StatLabels>
//                         </Grid>
//                       </Grid>
//                     </Grid>
//                   </Paper>
//                 )}

//                 <OverviewLabels sx={{ paddingBottom: '10px' }}>ENGAGEMENT SCORES</OverviewLabels>
//                 {loading ? (
//                   <Spinner />
//                 ) : (
//                   <Paper
//                     sx={{
//                       height: '120px',
//                       borderRadius: '3px',
//                       backgroundColor: '#FFFFFF',
//                       boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
//                     }}
//                   >
//                     <Grid
//                       container
//                       direction="row"
//                       sx={{
//                         paddingTop: '30px',
//                         paddingLeft: '16px',
//                         display: 'flex',
//                         alignItems: 'center',
//                         justifyContent: 'center',
//                       }}
//                     >
//                       <Grid item direction="column">
//                         <Grid item direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
//                           <PercentMain> {noOfStudent}</PercentMain>
//                         </Grid>
//                         <Grid item>
//                           <StatLabels>Number of Students</StatLabels>
//                         </Grid>
//                       </Grid>
//                       <Divider
//                         sx={{
//                           boxSizing: 'border-box',
//                           height: '68px',
//                           width: '2px',
//                           borderLeft: '2px solid #E9EAEB',
//                         }}
//                         variant="middle"
//                         flexItem
//                       />
//                       <Grid item direction="column" sx={{ paddingLeft: '20px' }}>
//                         <Grid item direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
//                           <PercentMain sx={{ color: '#002E5C' }}>{average}</PercentMain>
//                         </Grid>
//                         <Grid item>
//                           <StatLabels>Avg. Engagement Score</StatLabels>
//                         </Grid>
//                       </Grid>
//                       <Divider
//                         sx={{
//                           boxSizing: 'border-box',
//                           height: '68px',
//                           width: '2px',
//                           borderLeft: '2px solid #E9EAEB',
//                         }}
//                         variant="middle"
//                         flexItem
//                       />
//                       <Grid item direction="column" sx={{ paddingLeft: '20px' }}>
//                         <Grid item direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
//                           <PercentMain
//                             sx={{
//                               color: '#002E5C',
//                             }}
//                           >
//                             {percentage}%
//                           </PercentMain>
//                         </Grid>
//                         <Grid item>
//                           <StatLabels>Compared to Previous Period</StatLabels>
//                         </Grid>
//                       </Grid>
//                     </Grid>
//                   </Paper>
//                 )}
//               </Grid>
//               <Grid item xs={6}>
//                 <OverviewLabels sx={{ paddingBottom: '10px' }}>ENROLLMENT OVERVIEW</OverviewLabels>
//                 <Paper
//                   sx={{
//                     height: '443px',
//                     borderRadius: '3px',
//                     backgroundColor: '#FFFFFF',
//                     boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
//                     marginBottom: '15px',
//                     paddingTop: '30px',
//                   }}
//                 >
//                   <Grid
//                     container
//                     spacing={2}
//                     sx={{ dispaly: 'flex', justifyContent: 'center', paddingTop: '40px' }}
//                   >
//                     {loading ? <Spinner /> : <Funnel data={funnelDataStatic} />}
//                   </Grid>
//                 </Paper>
//               </Grid>
//             </Grid>
//           </Box>
//         </AccordionDetails>
//       </Accordion>

//       <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
//         <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
//           <Typography sx={{ color: '#002E5C', fontFamily: 'Helvetica', fontSize: '14px' }}>
//             <b>Student Search & Engagement</b>
//           </Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Box
//             sx={{
//               backgroundColor: '#FFFFFF',
//               paddingLeft: '20px',
//               paddingTop: '10px',
//               paddingBottom: '10px',
//               border: '1px solid rgba(0, 0, 0, .125)',
//             }}
//           >
//             <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
//               <Grid item xs={6}>
//                 {loading ? (
//                   <SpinnerDemandBuilder />
//                 ) : (
//                   <>
//                     <OverviewLabels sx={{ paddingBottom: '10px' }}>SEARCH</OverviewLabels>
//                     <Paper
//                       sx={{
//                         height: '120px',
//                         borderRadius: '3px',
//                         backgroundColor: '#FFFFFF',
//                         boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
//                         marginBottom: '15px',
//                       }}
//                     >
//                       <Grid
//                         container
//                         direction="row"
//                         sx={{
//                           paddingTop: '30px',
//                           paddingLeft: '16px',
//                           display: 'flex',
//                           justifyContent: 'center',
//                           alignItems: 'center',
//                         }}
//                       >
//                         <Grid item direction="column">
//                           <Grid item direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
//                             {loading ? (
//                               <Typography>Loading...</Typography>
//                             ) : (
//                               <PercentMain>{NumberConversion(search?.searched)}</PercentMain>
//                             )}
//                           </Grid>
//                           <Grid item>
//                             <StatLabels>Searched</StatLabels>
//                           </Grid>
//                         </Grid>
//                         <Divider
//                           sx={{
//                             boxSizing: 'border-box',
//                             height: '68px',
//                             width: '2px',
//                             borderLeft: '2px solid #E9EAEB',
//                           }}
//                           variant="middle"
//                           flexItem
//                         />
//                         <Grid item direction="column" sx={{ paddingLeft: '20px' }}>
//                           <Grid item direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
//                             {loading ? (
//                               <Typography>Loading...</Typography>
//                             ) : (
//                               <PercentMain sx={{ color: '#002E5C' }}>
//                                 {NumberConversion(search?.responders)}
//                               </PercentMain>
//                             )}
//                           </Grid>
//                           <Grid item>
//                             <StatLabels>Responders</StatLabels>
//                           </Grid>
//                         </Grid>
//                         <Divider
//                           sx={{
//                             boxSizing: 'border-box',
//                             height: '68px',
//                             width: '2px',
//                             borderLeft: '2px solid #E9EAEB',
//                           }}
//                           variant="middle"
//                           flexItem
//                         />
//                         <Grid item direction="column" sx={{ paddingLeft: '20px' }}>
//                           <Grid item direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
//                             {loading ? (
//                               <Typography>Loading...</Typography>
//                             ) : (
//                               <PercentMain
//                                 sx={{
//                                   color: '#002E5C',
//                                 }}
//                               >
//                                 {search?.responseRate}
//                               </PercentMain>
//                             )}
//                           </Grid>
//                           <Grid item>
//                             <StatLabels>Response Rate</StatLabels>
//                           </Grid>
//                         </Grid>
//                       </Grid>
//                     </Paper>
//                     <OverviewLabels sx={{ paddingBottom: '10px' }}>INQUIRIES</OverviewLabels>
//                     <Paper
//                       sx={{
//                         height: '120px',
//                         // width: '550px',
//                         borderRadius: '3px',
//                         backgroundColor: '#FFFFFF',
//                         boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
//                         marginBottom: '15px',
//                       }}
//                     >
//                       <Grid
//                         container
//                         direction="row"
//                         sx={{
//                           paddingTop: '30px',
//                           paddingLeft: '16px',
//                           display: 'flex',
//                           alignItems: 'center',
//                           justifyContent: 'center',
//                         }}
//                       >
//                         <Grid item direction="column">
//                           <Grid item direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
//                             {loading ? (
//                               <Typography>Loading...</Typography>
//                             ) : (
//                               <PercentMain>{inquiries?.completed}</PercentMain>
//                             )}
//                           </Grid>
//                           <Grid item>
//                             <StatLabels>Completed</StatLabels>
//                           </Grid>
//                         </Grid>
//                         <Divider
//                           sx={{
//                             boxSizing: 'border-box',
//                             height: '68px',
//                             width: '2px',
//                             borderLeft: '2px solid #E9EAEB',
//                           }}
//                           variant="middle"
//                           flexItem
//                         />
//                         <Grid item direction="column" sx={{ paddingLeft: '20px' }}>
//                           <Grid item direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
//                             {loading ? (
//                               <Typography>Loading...</Typography>
//                             ) : (
//                               <PercentMain sx={{ color: '#002E5C' }}>
//                                 {inquiries?.completedSurvey}
//                               </PercentMain>
//                             )}
//                           </Grid>
//                           <Grid item>
//                             <StatLabels>Completed Survey</StatLabels>
//                           </Grid>
//                         </Grid>
//                         <Divider
//                           sx={{
//                             boxSizing: 'border-box',
//                             height: '68px',
//                             width: '2px',
//                             borderLeft: '2px solid #E9EAEB',
//                           }}
//                           variant="middle"
//                           flexItem
//                         />
//                         <Grid item direction="column" sx={{ paddingLeft: '20px' }}>
//                           <Grid item direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
//                             {loading ? (
//                               <Typography>Loading...</Typography>
//                             ) : (
//                               <PercentMain
//                                 sx={{
//                                   color: '#002E5C',
//                                 }}
//                               >
//                                 {inquiries?.completedSurveyPercentage}
//                               </PercentMain>
//                             )}
//                           </Grid>
//                           <Grid item>
//                             <StatLabels>Interested</StatLabels>
//                           </Grid>
//                         </Grid>
//                       </Grid>
//                     </Paper>
//                     <OverviewLabels sx={{ paddingBottom: '10px' }}>APPLICANTS</OverviewLabels>
//                     <Paper
//                       sx={{
//                         height: '120px',
//                         borderRadius: '3px',
//                         backgroundColor: '#FFFFFF',
//                         boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
//                       }}
//                     >
//                       <Grid
//                         container
//                         direction="row"
//                         sx={{
//                           paddingTop: '30px',
//                           paddingLeft: '16px',
//                           display: 'flex',
//                           alignItems: 'center',
//                           justifyContent: 'center',
//                         }}
//                       >
//                         <Grid item direction="column">
//                           <Grid item direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
//                             {loading ? (
//                               <Typography>Loading...</Typography>
//                             ) : (
//                               <PercentMain>{applicants?.submitted}</PercentMain>
//                             )}
//                           </Grid>
//                           <Grid item>
//                             <StatLabels>Submitted</StatLabels>
//                           </Grid>
//                         </Grid>
//                         <Divider
//                           sx={{
//                             boxSizing: 'border-box',
//                             height: '68px',
//                             width: '2px',
//                             borderLeft: '2px solid #E9EAEB',
//                           }}
//                           variant="middle"
//                           flexItem
//                         />
//                         <Grid item direction="column" sx={{ paddingLeft: '20px' }}>
//                           <Grid item direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
//                             {loading ? (
//                               <Typography>Loading...</Typography>
//                             ) : (
//                               <PercentMain sx={{ color: '#002E5C' }}>
//                                 {applicants?.submissionRate}
//                               </PercentMain>
//                             )}
//                           </Grid>
//                           <Grid item>
//                             <StatLabels>Submission Rate</StatLabels>
//                           </Grid>
//                         </Grid>

//                         <Grid item direction="column" sx={{ paddingLeft: '20px' }}>
//                           <Grid
//                             item
//                             direction="row"
//                             sx={{ display: 'flex', alignItems: 'center' }}
//                            />
//                         </Grid>
//                       </Grid>
//                     </Paper>
//                   </>
//                 )}
//               </Grid>

//               <Grid item xs={6}>
//                 {loading ? (
//                   <Spinner />
//                 ) : (
//                   <>
//                     <OverviewLabels sx={{ paddingBottom: '10px' }}>
//                       ENROLLMENT OVERVIEW
//                     </OverviewLabels>
//                     <Paper
//                       sx={{
//                         height: '443px',
//                         borderRadius: '3px',
//                         backgroundColor: '#FFFFFF',
//                         boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
//                         marginBottom: '15px',
//                         paddingTop: '30px',
//                       }}
//                     >
//                       <Grid
//                         container
//                         spacing={2}
//                         sx={{ dispaly: 'flex', justifyContent: 'center', paddingTop: '40px' }}
//                       >
//                         <Funnel data={funnelDataStatic} />
//                       </Grid>
//                     </Paper>
//                   </>
//                 )}
//               </Grid>
//             </Grid>
//           </Box>
//         </AccordionDetails>
//       </Accordion>

//       <OverviewLabels sx={{ paddingTop: '25px', paddingBottom: '10px', paddingLeft: '20px' }}>
//         {OVERVIEW.LAUNCH_AREAS}
//       </OverviewLabels>
//       <Grid
//         container
//         direction="row"
//         display="flex"
//         spacing={2}
//         sx={{ marginLeft: '7px', marginBottom: '20px' }}
//       >
//         <Grid item>
//           <Card
//             sx={{
//               height: '189px',
//               width: '371px',
//             }}
//           >
//             <CardContent>
//               <Grid item display="flex" alignItems="center">
//                 <CachedIcon sx={{ color: '#89949E', width: '30px', height: '30px' }} />
//                 <LaunchLabels sx={{ paddingLeft: '10px', paddingTop: '2px' }}>
//                   Sync Center
//                 </LaunchLabels>
//               </Grid>
//               <LaunchDetails variant="body2" color="text.secondary">
//                 <ul>
//                   <li>Upload data to RNL</li>
//                   <li>Track progress of the processing of uploads</li>
//                 </ul>
//               </LaunchDetails>
//               <Grid container direction="row">
//                 <LaunchLink
//                   underline="none"
//                   href="https://myportaluat.rnl.com/PBD-005000/Home/IndexA?fn=PGE-000031&lang=1033&source=0"
//                   target="_blank"
//                   sx={{
//                     marginBottom: '5px',
//                     cursor: 'pointer',
//                     display: 'flex',
//                     alignItems: 'center',
//                     paddingLeft: '171px',
//                   }}
//                 >
//                   {OVERVIEW.LAUNCH_SYNC_CENTER} <NavigateNextIcon />
//                 </LaunchLink>
//               </Grid>
//             </CardContent>
//           </Card>
//         </Grid>

//         <Grid item>
//           <Card
//             sx={{
//               height: '189px',
//               width: '372px',
//             }}
//           >
//             <CardContent>
//               <Grid item display="flex" alignItems="center">
//                 <PsychologyIcon sx={{ color: '#89949E', width: '30px', height: '30px' }} />

//                 <LaunchLabels sx={{ paddingLeft: '10px', paddingTop: '2px' }}>
//                   SmartView
//                 </LaunchLabels>
//               </Grid>
//               <LaunchDetails variant="body2" color="text.secondary">
//                 <ul>
//                   <li>Access reports and insights</li>
//                   <li>Track progress of the processing of uploads</li>
//                 </ul>
//               </LaunchDetails>
//               <Grid container direction="row">
//                 <LaunchLink
//                   underline="none"
//                   sx={{
//                     marginBottom: '5px',
//                     cursor: 'pointer',
//                     display: 'flex',
//                     alignItems: 'center',
//                     paddingLeft: '145px',
//                   }}
//                 >
//                   {OVERVIEW.LAUNCH_SMARTVIEW} <NavigateNextIcon />
//                 </LaunchLink>
//               </Grid>
//             </CardContent>
//           </Card>
//         </Grid>
//         <Grid item>
//           <Card
//             sx={{
//               height: '189px',
//               width: '372px',
//             }}
//           >
//             <CardContent>
//               <Grid item display="flex" alignItems="center">
//                 <SchoolIcon sx={{ color: '#89949E', width: '30px', height: '30px' }} />
//                 <LaunchLabels sx={{ paddingLeft: '10px', paddingTop: '2px' }}>
//                   Knowledge Center
//                 </LaunchLabels>
//               </Grid>
//               <LaunchDetails variant="body2" color="text.secondary">
//                 <ul>
//                   <li>Review training videos and user guides</li>
//                   <li>Access thought leadership content</li>
//                 </ul>
//               </LaunchDetails>
//               <Grid container direction="row">
//                 <LaunchLink
//                   underline="none"
//                   href="https://rnlu.trainingtoday.com/admin/dashboard"
//                   target="_blank"
//                   sx={{
//                     marginBottom: '5px',
//                     cursor: 'pointer',
//                     display: 'flex',
//                     alignItems: 'center',
//                     paddingLeft: '135px',
//                   }}
//                 >
//                   {OVERVIEW.LAUNCH_KNOWLEDGE_CENTER} <NavigateNextIcon />
//                 </LaunchLink>
//               </Grid>
//             </CardContent>
//           </Card>
//         </Grid>
//         <Grid item>
//           <Card
//             sx={{
//               height: '189px',
//               width: '372px',
//             }}
//           >
//             <CardContent>
//               <Grid item display="flex" alignItems="center">
//                 <PeopleIcon sx={{ color: '#89949E', width: '30px', height: '30px' }} />
//                 <LaunchLabels sx={{ paddingLeft: '10px', paddingTop: '2px' }}>
//                   Collaboration Center
//                 </LaunchLabels>
//               </Grid>
//               <LaunchDetails variant="body2" color="text.secondary">
//                 <ul>
//                   <li>Access project management tasks</li>
//                   <li>Submit, edit, review and content</li>
//                 </ul>
//               </LaunchDetails>
//               <Grid container direction="row">
//                 <LaunchLink
//                   underline="none"
//                   href="https://app6.workamajig.com/platinum/"
//                   target="_blank"
//                   sx={{
//                     marginBottom: '5px',
//                     cursor: 'pointer',
//                     display: 'flex',
//                     alignItems: 'center',
//                     paddingLeft: '116px',
//                   }}
//                 >
//                   {OVERVIEW.LAUNCH_COLLABORATION_CENTER} <NavigateNextIcon />
//                 </LaunchLink>
//               </Grid>
//             </CardContent>
//           </Card>
//         </Grid>
//         <Grid item>
//           <Card
//             sx={{
//               height: '189px',
//               width: '372px',
//             }}
//           >
//             <CardContent>
//               <Grid item display="flex" alignItems="center">
//                 <img src={rnlLogo} alt="" style={{ width: '50px', height: '35px' }} />
//                 <LaunchLabels sx={{ paddingLeft: '3px', paddingTop: '2px' }}>
//                   RNL Product Center
//                 </LaunchLabels>
//               </Grid>
//               <LaunchDetails variant="body2" color="text.secondary">
//                 <ul>
//                   <li>
//                     <Link href="/enrollment" sx={{ textDecoration: 'none', color: 'black' }}>
//                       Enrollment
//                     </Link>
//                   </li>
//                   <li>
//                     <Link href="/advanced-finaid" sx={{ textDecoration: 'none', color: 'black' }}>
//                       Advanced FinAid
//                     </Link>
//                   </li>
//                   <li>
//                     <Link href="/student-success" sx={{ textDecoration: 'none', color: 'black' }}>
//                       Student Success
//                     </Link>
//                   </li>
//                 </ul>
//               </LaunchDetails>
//               <Grid container direction="row">
//                 <LaunchLink
//                   underline="none"
//                   sx={{
//                     marginBottom: '5px',
//                     cursor: 'pointer',
//                     display: 'flex',
//                     alignItems: 'center',
//                     paddingLeft: '131px',
//                   }}
//                 >
//                   {OVERVIEW.LAUNCH_RNL_PRODUCT_CENTER}
//                   <NavigateNextIcon />
//                 </LaunchLink>
//               </Grid>
//             </CardContent>
//           </Card>
//         </Grid>
//       </Grid>
//     </div>
//   );
// }

export default function CustomizedAccordions() {
  const { instance } = useMsal();
  const navigate = useNavigate();
  let activeAccount;
  let email;

  if (instance) {
    activeAccount = instance.getActiveAccount();
    email = activeAccount.idTokenClaims.tableau_email;
  }
  const dispatch = useDispatch();
  const kpiData = useSelector((state) => state.kpiDetails.data);
  const loading = useSelector((state) => state.kpiDetails.loading);
  const error = useSelector((state) => state.kpiDetails?.data?.data?.status);

  const kpiDetails = useSelector((state) => state.engagementScoreKpi.data);
  // const loading = useSelector((state) => state.engagementScoreKpi.KPIloading);
  const students = kpiDetails.numberOfStudent;
  const noOfStudent = NumberConversion(students);
  const average = kpiDetails.average ? kpiDetails.average.toFixed(2) : '';
  const { percentage } = kpiDetails;

  const search = useSelector((state) => state.demandBuilderKpi.data.search);
  const applicants = useSelector((state) => state.demandBuilderKpi.data.applicants);
  const inquiries = useSelector((state) => state.demandBuilderKpi.data.inquiries);
  // const loading = useSelector((state) => state.demandBuilderKpi.loading);
  const forecastPlus = useSelector((state) => state.forecastPlus.data);

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const initFetch = useCallback(() => {
    dispatch(fetchKPIDetails(email));
    // dispatch(fetchForecastPlus(email));
    // dispatch(fetchKPIValues());
    // dispatch(fetchDemandBuilderKPIValues());
  }, [dispatch]);

  useEffect(() => {
    if (_.isEmpty(kpiData)) {
      initFetch();
    }
  }, [initFetch]);

  return (
    <>
      <>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography sx={{ color: '#002E5C', fontFamily: 'Helvetica', fontSize: '14px' }}>
              <b>Student Search & Engagement - Overview</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                backgroundColor: '#FFFFFF',
                paddingLeft: '20px',
                paddingTop: '10px',
                paddingBottom: '10px',
                border: '1px solid rgba(0, 0, 0, .125)',
              }}
            >
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                  <OverviewLabels sx={{ paddingBottom: '10px' }}>CONVERSION</OverviewLabels>
                  <Paper
                    sx={{
                      height: '120px',
                      borderRadius: '3px',
                      backgroundColor: '#FFFFFF',
                      boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
                      marginBottom: '15px',
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      sx={{
                        paddingTop: '30px',
                        paddingLeft: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {/* <Grid container direction="column"></Grid> */}

                      <Grid item>
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                          <PercentMain>3%</PercentMain>
                        </Grid>
                        <Grid item>
                          <StatLabels>Searched</StatLabels>
                        </Grid>
                      </Grid>
                      <Divider
                        sx={{
                          boxSizing: 'border-box',
                          height: '68px',
                          width: '2px',
                          borderLeft: '2px solid #E9EAEB',
                        }}
                        variant="middle"
                        flexItem
                      />
                      <Grid item sx={{ paddingLeft: '20px' }}>
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                          <PercentMain sx={{ color: '#002E5C' }}>5%</PercentMain>
                        </Grid>
                        <Grid item>
                          <StatLabels>Responders</StatLabels>
                        </Grid>
                      </Grid>
                      <Divider
                        sx={{
                          boxSizing: 'border-box',
                          height: '68px',
                          width: '2px',
                          borderLeft: '2px solid #E9EAEB',
                        }}
                        variant="middle"
                        flexItem
                      />
                      <Grid item sx={{ paddingLeft: '20px' }}>
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                          <PercentMain
                            sx={{
                              color: '#002E5C',
                            }}
                          >
                            2%
                          </PercentMain>
                        </Grid>
                        <Grid item>
                          <StatLabels>Response Rate</StatLabels>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>

                  <OverviewLabels sx={{ paddingBottom: '10px' }}>FORECAST PLUS</OverviewLabels>

                  {error && error !== 200 ? (
                    <Box
                      sx={{
                        height: '120px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '3px',
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
                        marginBottom: '15px',
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#D3D3D3',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {' '}
                        No data to display
                      </Typography>{' '}
                    </Box>
                  ) : (
                    <>
                      {' '}
                      {loading ? (
                        <Spinner />
                      ) : (
                        <Paper
                          sx={{
                            height: '120px',
                            // width: '550px',
                            borderRadius: '3px',
                            backgroundColor: '#FFFFFF',
                            boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
                            marginBottom: '15px',
                          }}
                        >
                          <Grid
                            container
                            direction="row"
                            sx={{
                              paddingTop: '30px',
                              paddingLeft: '16px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Grid item>
                              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                <PercentMain>{kpiData?.forecastplus?.projection}</PercentMain>
                              </Grid>
                              <Grid item>
                                <StatLabels>Projection</StatLabels>
                              </Grid>
                            </Grid>
                            <Divider
                              sx={{
                                boxSizing: 'border-box',
                                height: '68px',
                                width: '2px',
                                borderLeft: '2px solid #E9EAEB',
                              }}
                              variant="middle"
                              flexItem
                            />
                            <Grid item sx={{ paddingLeft: '20px' }}>
                              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                <PercentMain sx={{ color: '#002E5C' }}>
                                  {kpiData?.forecastplus?.actual}
                                </PercentMain>
                              </Grid>
                              <Grid item>
                                <StatLabels>Actual</StatLabels>
                              </Grid>
                            </Grid>
                            <Divider
                              sx={{
                                boxSizing: 'border-box',
                                height: '68px',
                                width: '2px',
                                borderLeft: '2px solid #E9EAEB',
                              }}
                              variant="middle"
                              flexItem
                            />
                            <Grid item sx={{ paddingLeft: '20px' }}>
                              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                <PercentMain
                                  sx={{
                                    color: '#002E5C',
                                  }}
                                >
                                  {kpiData?.forecastplus?.forecastPercentage}%
                                </PercentMain>
                              </Grid>
                              <Grid item>
                                <StatLabels>Actual / Projection</StatLabels>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      )}
                    </>
                  )}

                  <OverviewLabels sx={{ paddingBottom: '10px' }}>ENGAGEMENT SCORES</OverviewLabels>
                  {error && error !== 200 ? (
                    <Box
                      sx={{
                        height: '120px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '3px',
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
                        marginBottom: '15px',
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#D3D3D3',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {' '}
                        No data to display
                      </Typography>{' '}
                    </Box>
                  ) : (
                    <>
                      {' '}
                      {loading ? (
                        <Spinner />
                      ) : (
                        <Paper
                          sx={{
                            height: '120px',
                            borderRadius: '3px',
                            backgroundColor: '#FFFFFF',
                            boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
                          }}
                        >
                          <Grid
                            container
                            direction="row"
                            sx={{
                              paddingTop: '30px',
                              paddingLeft: '16px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Grid item>
                              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                <PercentMain>
                                  {' '}
                                  {NumberConversion(
                                    kpiData?.engagementScore?.numberOfStudent,
                                  ).toString()}
                                </PercentMain>
                              </Grid>
                              <Grid item>
                                <StatLabels>Number of Students</StatLabels>
                              </Grid>
                            </Grid>
                            <Divider
                              sx={{
                                boxSizing: 'border-box',
                                height: '68px',
                                width: '2px',
                                borderLeft: '2px solid #E9EAEB',
                              }}
                              variant="middle"
                              flexItem
                            />
                            <Grid item sx={{ paddingLeft: '20px' }}>
                              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                <PercentMain sx={{ color: '#002E5C' }}>
                                  {NumberConversion(kpiData?.engagementScore?.average)
                                    .toFixed(2)
                                    .toString()}
                                </PercentMain>
                              </Grid>
                              <Grid item>
                                <StatLabels>Avg. Engagement Score</StatLabels>
                              </Grid>
                            </Grid>
                            <Divider
                              sx={{
                                boxSizing: 'border-box',
                                height: '68px',
                                width: '2px',
                                borderLeft: '2px solid #E9EAEB',
                              }}
                              variant="middle"
                              flexItem
                            />
                            <Grid item sx={{ paddingLeft: '20px' }}>
                              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                <PercentMain
                                  sx={{
                                    color: '#002E5C',
                                  }}
                                >
                                  {NumberConversion(
                                    kpiData?.engagementScore?.percentage,
                                  ).toString()}
                                  %
                                </PercentMain>
                              </Grid>
                              <Grid item>
                                <StatLabels>Compared to Previous Period</StatLabels>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <OverviewLabels sx={{ paddingBottom: '10px' }}>
                    ENROLLMENT OVERVIEW
                  </OverviewLabels>
                  {error && error !== 200 ? (
                    <Box
                      sx={{
                        height: '445px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '3px',
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
                        marginBottom: '15px',
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#D3D3D3',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {' '}
                        No data to display
                      </Typography>{' '}
                    </Box>
                  ) : (
                    <>
                      {' '}
                      <Paper
                        sx={{
                          height: '443px',
                          borderRadius: '3px',
                          backgroundColor: '#FFFFFF',
                          boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
                          marginBottom: '15px',
                          paddingTop: '30px',
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{ dispaly: 'flex', justifyContent: 'center', paddingTop: '40px' }}
                        >
                          {loading ? <Spinner /> : <Funnel data={kpiData?.funnelStage} />}
                        </Grid>
                      </Paper>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography sx={{ color: '#002E5C', fontFamily: 'Helvetica', fontSize: '14px' }}>
              <b>Student Search & Engagement</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                backgroundColor: '#FFFFFF',
                paddingLeft: '20px',
                paddingTop: '10px',
                paddingBottom: '10px',
                border: '1px solid rgba(0, 0, 0, .125)',
              }}
            >
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                  <OverviewLabels sx={{ paddingBottom: '10px' }}>SEARCH</OverviewLabels>
                  {error && error !== 200 ? (
                    <Box
                      sx={{
                        height: '120px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '3px',
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
                        marginBottom: '15px',
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#D3D3D3',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {' '}
                        No data to display
                      </Typography>{' '}
                    </Box>
                  ) : (
                    <>
                      {' '}
                      {loading ? (
                        <SpinnerDemandBuilder />
                      ) : (
                        <Paper
                          sx={{
                            height: '120px',
                            borderRadius: '3px',
                            backgroundColor: '#FFFFFF',
                            boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
                            marginBottom: '15px',
                          }}
                        >
                          <Grid
                            container
                            direction="row"
                            sx={{
                              paddingTop: '30px',
                              paddingLeft: '16px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Grid item>
                              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                {loading ? (
                                  <Typography>Loading...</Typography>
                                ) : (
                                  <PercentMain>
                                    {NumberConversion(kpiData?.search?.searched).toString()}
                                  </PercentMain>
                                )}
                              </Grid>
                              <Grid item>
                                <StatLabels>Searched</StatLabels>
                              </Grid>
                            </Grid>
                            <Divider
                              sx={{
                                boxSizing: 'border-box',
                                height: '68px',
                                width: '2px',
                                borderLeft: '2px solid #E9EAEB',
                              }}
                              variant="middle"
                              flexItem
                            />
                            <Grid item sx={{ paddingLeft: '20px' }}>
                              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                {loading ? (
                                  <Typography>Loading...</Typography>
                                ) : (
                                  <PercentMain sx={{ color: '#002E5C' }}>
                                    {NumberConversion(kpiData?.search?.responders).toString()}
                                  </PercentMain>
                                )}
                              </Grid>
                              <Grid item>
                                <StatLabels>Responders</StatLabels>
                              </Grid>
                            </Grid>
                            <Divider
                              sx={{
                                boxSizing: 'border-box',
                                height: '68px',
                                width: '2px',
                                borderLeft: '2px solid #E9EAEB',
                              }}
                              variant="middle"
                              flexItem
                            />
                            <Grid item sx={{ paddingLeft: '20px' }}>
                              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                {loading ? (
                                  <Typography>Loading...</Typography>
                                ) : (
                                  <PercentMain
                                    sx={{
                                      color: '#002E5C',
                                    }}
                                  >
                                    {kpiData?.search?.responseRate}
                                  </PercentMain>
                                )}
                              </Grid>
                              <Grid item>
                                <StatLabels>Response Rate</StatLabels>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      )}
                    </>
                  )}

                  <OverviewLabels sx={{ paddingBottom: '10px' }}>INQUIRIES</OverviewLabels>
                  {error && error !== 200 ? (
                    <Box
                      sx={{
                        height: '120px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '3px',
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
                        marginBottom: '15px',
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#D3D3D3',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {' '}
                        No data to display
                      </Typography>{' '}
                    </Box>
                  ) : (
                    <>
                      {' '}
                      {loading ? (
                        <SpinnerDemandBuilder />
                      ) : (
                        <Paper
                          sx={{
                            height: '120px',
                            // width: '550px',
                            borderRadius: '3px',
                            backgroundColor: '#FFFFFF',
                            boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
                            marginBottom: '15px',
                          }}
                        >
                          <Grid
                            container
                            direction="row"
                            sx={{
                              paddingTop: '30px',
                              paddingLeft: '16px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Grid item>
                              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                {loading ? (
                                  <Typography>Loading...</Typography>
                                ) : (
                                  <PercentMain>{kpiData?.inquiries?.completed}</PercentMain>
                                )}
                              </Grid>
                              <Grid item>
                                <StatLabels>Completed</StatLabels>
                              </Grid>
                            </Grid>
                            <Divider
                              sx={{
                                boxSizing: 'border-box',
                                height: '68px',
                                width: '2px',
                                borderLeft: '2px solid #E9EAEB',
                              }}
                              variant="middle"
                              flexItem
                            />
                            <Grid item sx={{ paddingLeft: '20px' }}>
                              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                {loading ? (
                                  <Typography>Loading...</Typography>
                                ) : (
                                  <PercentMain sx={{ color: '#002E5C' }}>
                                    {kpiData?.inquiries?.completedSurvey}
                                  </PercentMain>
                                )}
                              </Grid>
                              <Grid item>
                                <StatLabels>Completed Survey</StatLabels>
                              </Grid>
                            </Grid>
                            <Divider
                              sx={{
                                boxSizing: 'border-box',
                                height: '68px',
                                width: '2px',
                                borderLeft: '2px solid #E9EAEB',
                              }}
                              variant="middle"
                              flexItem
                            />
                            <Grid item sx={{ paddingLeft: '20px' }}>
                              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                {loading ? (
                                  <Typography>Loading...</Typography>
                                ) : (
                                  <PercentMain
                                    sx={{
                                      color: '#002E5C',
                                    }}
                                  >
                                    {kpiData?.inquiries?.completedSurveyPercentage}
                                  </PercentMain>
                                )}
                              </Grid>
                              <Grid item>
                                <StatLabels>Interested</StatLabels>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      )}
                    </>
                  )}

                  <OverviewLabels sx={{ paddingBottom: '10px' }}>APPLICANTS</OverviewLabels>
                  {error && error !== 200 ? (
                    <Box
                      sx={{
                        height: '120px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '3px',
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
                        marginBottom: '15px',
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#D3D3D3',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {' '}
                        No data to display
                      </Typography>{' '}
                    </Box>
                  ) : (
                    <>
                      {' '}
                      {loading ? (
                        <SpinnerDemandBuilder />
                      ) : (
                        <Paper
                          sx={{
                            height: '120px',
                            borderRadius: '3px',
                            backgroundColor: '#FFFFFF',
                            boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
                          }}
                        >
                          <Grid
                            container
                            direction="row"
                            sx={{
                              paddingTop: '30px',
                              paddingLeft: '16px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Grid item>
                              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                {loading ? (
                                  <Typography>Loading...</Typography>
                                ) : (
                                  <PercentMain>{kpiData.applicants?.submitted}</PercentMain>
                                )}
                              </Grid>
                              <Grid item>
                                <StatLabels>Submitted</StatLabels>
                              </Grid>
                            </Grid>
                            <Divider
                              sx={{
                                boxSizing: 'border-box',
                                height: '68px',
                                width: '2px',
                                borderLeft: '2px solid #E9EAEB',
                              }}
                              variant="middle"
                              flexItem
                            />
                            <Grid item sx={{ paddingLeft: '20px' }}>
                              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                {loading ? (
                                  <Typography>Loading...</Typography>
                                ) : (
                                  <PercentMain sx={{ color: '#002E5C' }}>
                                    {kpiData.applicants?.submissionRate}
                                  </PercentMain>
                                )}
                              </Grid>
                              <Grid item>
                                <StatLabels>Submission Rate</StatLabels>
                              </Grid>
                            </Grid>

                            <Grid item sx={{ paddingLeft: '20px' }}>
                              <Grid item sx={{ display: 'flex', alignItems: 'center' }} />
                            </Grid>
                          </Grid>
                        </Paper>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <OverviewLabels sx={{ paddingBottom: '10px' }}>
                    ENROLLMENT OVERVIEW
                  </OverviewLabels>

                  {error && error !== 200 ? (
                    <Box
                      sx={{
                        height: '450px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '3px',
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
                        marginBottom: '15px',
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#D3D3D3',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {' '}
                        No data to display
                      </Typography>{' '}
                    </Box>
                  ) : (
                    <>
                      {' '}
                      {loading ? (
                        <Spinner />
                      ) : (
                        <Paper
                          sx={{
                            height: '443px',
                            borderRadius: '3px',
                            backgroundColor: '#FFFFFF',
                            boxShadow: '0 0 3px 0 rgba(0,0,0,0.15)',
                            marginBottom: '15px',
                            paddingTop: '30px',
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            sx={{ dispaly: 'flex', justifyContent: 'center', paddingTop: '40px' }}
                          >
                            <Funnel data={kpiData.funnelStage} />
                          </Grid>
                        </Paper>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </>

      <OverviewLabels sx={{ paddingTop: '25px', paddingBottom: '10px', paddingLeft: '20px' }}>
        {OVERVIEW.LAUNCH_AREAS}
      </OverviewLabels>
      <Grid
        container
        direction="row"
        display="flex"
        spacing={2}
        sx={{ marginLeft: '7px', marginBottom: '20px' }}
      >
        <Grid item>
          <Card
            sx={{
              height: '189px',
              width: '371px',
            }}
          >
            <CardContent>
              <Grid item display="flex" alignItems="center">
                <CachedIcon sx={{ color: '#89949E', width: '30px', height: '30px' }} />
                <LaunchLabels sx={{ paddingLeft: '10px', paddingTop: '2px' }}>
                  Sync Center
                </LaunchLabels>
              </Grid>
              <LaunchDetails variant="body2" color="text.secondary">
                <li>Upload data to RNL</li>
                <li>Track progress of the processing of uploads</li>
              </LaunchDetails>
              <Grid container direction="row">
                <LaunchLink
                  underline="none"
                  href={SYNC_CENTER_URL}
                  target="_blank"
                  sx={{
                    marginBottom: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '171px',
                  }}
                >
                  {OVERVIEW.LAUNCH_SYNC_CENTER} <NavigateNextIcon />
                </LaunchLink>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item>
          <Card
            sx={{
              height: '189px',
              width: '372px',
            }}
          >
            <CardContent>
              <Grid item display="flex" alignItems="center">
                <PsychologyIcon sx={{ color: '#89949E', width: '30px', height: '30px' }} />

                <LaunchLabels sx={{ paddingLeft: '10px', paddingTop: '2px' }}>
                  SmartView
                </LaunchLabels>
              </Grid>
              <LaunchDetails variant="body2" color="text.secondary">
                <li>Access reports and insights</li>
                <li>Track progress of the processing of uploads</li>
              </LaunchDetails>
              <Grid container direction="row">
                <LaunchLink
                  underline="none"
                  onClick={() => {
                    navigate('/smartview');
                  }}
                  sx={{
                    marginBottom: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '145px',
                  }}
                >
                  {OVERVIEW.LAUNCH_SMARTVIEW} <NavigateNextIcon />
                </LaunchLink>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card
            sx={{
              height: '189px',
              width: '372px',
            }}
          >
            <CardContent>
              <Grid item display="flex" alignItems="center">
                <SchoolIcon sx={{ color: '#89949E', width: '30px', height: '30px' }} />
                <LaunchLabels sx={{ paddingLeft: '10px', paddingTop: '2px' }}>
                  Knowledge Center
                </LaunchLabels>
              </Grid>
              <LaunchDetails variant="body2" color="text.secondary">
                <li>Review training videos and user guides</li>
                <li>Access thought leadership content</li>
              </LaunchDetails>
              <Grid container direction="row">
                <LaunchLink
                  underline="none"
                  href={KNOWLEDGE_CENTER_URL}
                  target="_blank"
                  sx={{
                    marginBottom: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '135px',
                  }}
                >
                  {OVERVIEW.LAUNCH_KNOWLEDGE_CENTER} <NavigateNextIcon />
                </LaunchLink>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card
            sx={{
              height: '189px',
              width: '372px',
            }}
          >
            <CardContent>
              <Grid item display="flex" alignItems="center">
                <PeopleIcon sx={{ color: '#89949E', width: '30px', height: '30px' }} />
                <LaunchLabels sx={{ paddingLeft: '10px', paddingTop: '2px' }}>
                  Collaboration Center
                </LaunchLabels>
              </Grid>
              <LaunchDetails variant="body2" color="text.secondary">
                <li>Access project management tasks</li>
                <li>Submit, edit, review and content</li>
              </LaunchDetails>
              <Grid container direction="row">
                <LaunchLink
                  underline="none"
                  href={COLLABORATION_CENTER_URL}
                  target="_blank"
                  sx={{
                    marginBottom: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '116px',
                  }}
                >
                  {OVERVIEW.LAUNCH_COLLABORATION_CENTER} <NavigateNextIcon />
                </LaunchLink>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card
            sx={{
              height: '189px',
              width: '372px',
            }}
          >
            <CardContent>
              <Grid item display="flex" alignItems="center">
                <img src={rnlLogo} alt="" style={{ width: '50px', height: '35px' }} />
                <LaunchLabels sx={{ paddingLeft: '3px', paddingTop: '2px' }}>
                  RNL Product Center
                </LaunchLabels>
              </Grid>
              <LaunchDetails variant="body2" color="text.secondary">
                <li>
                  <RNLLaunchLink
                    // to='/enrollment'
                    onClick={() => {
                      navigate('/enrollment');
                    }}
                    sx={{ textDecoration: 'none', color: 'black' }}
                  >
                    Enrollment
                  </RNLLaunchLink>
                </li>
                <li>
                  <RNLLaunchLink
                    onClick={() => {
                      navigate('/advanced-finaid');
                    }}
                    sx={{ textDecoration: 'none', color: 'black' }}
                  >
                    Advanced FinAid
                  </RNLLaunchLink>
                </li>
                <li>
                  <RNLLaunchLink
                    onClick={() => {
                      navigate('/student-success');
                    }}
                    sx={{ textDecoration: 'none', color: 'black' }}
                  >
                    Student Success
                  </RNLLaunchLink>
                </li>
              </LaunchDetails>
              <Grid container direction="row">
                <LaunchLink
                  underline="none"
                  onClick={() => {
                    navigate('/enrollment');
                  }}
                  sx={{
                    marginBottom: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '131px',
                  }}
                >
                  {OVERVIEW.LAUNCH_RNL_PRODUCT_CENTER}
                  <NavigateNextIcon />
                </LaunchLink>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
