import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';

export const FooterDetails = styled(Typography)(() => ({
  fontSize: '15px',
  color: '#89949E',
  fontFamily: 'Helvetica Neue Regular',
  '&.MuiTypography-root': {
    fontFamily: 'inherit',
  },
}));

export const FooterLink = styled(Link)(() => ({
  fontSize: '15px',
  color: '#89949E',
  fontFamily: 'Helvetica Neue Regular',
  cursor: 'pointer',
  textTransform: 'none',
  '&.MuiButton-root': {
    fontFamily: 'inherit',
  },
}));

export const FooterDivider = styled(Divider)(() => ({
  borderLeft: '2px solid #E9EAEB',
  height: '30px',
  width: '1px',
  marginTop: '31px',
}));
