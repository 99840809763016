/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  alertTitle: '',
  alertMessage: '',
  openAlert: false,
  alertType: '',
};

const genericAlertSlice = createSlice({
  name: 'genericAlert',
  initialState,
  reducers: {
    getAlert: (state, action) => {
      state.alertTitle = action.payload.alertTitle;
      state.alertMessage = action.payload.alertMessage;
      state.openAlert = action.payload.openAlert;
      state.alertType = action.payload.alertType;
    },
  },
});

export const { getAlert } = genericAlertSlice.actions;
export default genericAlertSlice.reducer;
