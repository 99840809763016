import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SSORedirectService from '../services/SSORedirectService';

const initialState = {
  data: {},
  loading: false,
  error: '',
};

export const fetchUserDetails = createAsyncThunk('ssoRedirect/fetchUserDetails', async (email) => {
  const response = await SSORedirectService.getUserDetails(email);
  return response;
});

const ssoRedirectSlice = createSlice({
  name: 'ssoRedirect',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUserDetails.pending]: (state) => {
      state.loading = true;
    },
    [fetchUserDetails.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchUserDetails.rejected]: (state) => {
      state.loading = false;
      state.error = 'Error, something went wrong. Contact support if problem persist';
    },
  },
});

export default ssoRedirectSlice.reducer;
