import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

export const CarousalArrowIosIconBack = styled(IconButton)(() => ({
  verticalAlign: 'middle',
  color: '#6B7782',
  fontSize: '14px',
  cursor: 'pointer',
}));

export const CarousalArrowIosIconForword = styled(IconButton)(() => ({
  verticalAlign: 'middle',
  color: '#6B7782',
  fontSize: '14px',
  cursor: 'pointer',
}));
