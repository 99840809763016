import axios from 'axios';

const url = 'https://rnl-sse-prod.azure-api.net/decode/DecodeJWTToken'
// const url = 'https://rnl-sse-dev-dev-cp.azure-api.net/decode/decodeJWTToken';
const token = window.location.hash.split('=')[1];
console.log('==window.location.hash===', window.location.hash)
const headers = {
  'Content-Type': 'application/json',
  'Ocp-Apim-Subscription-Key': '0c94a6eff20f4ddc846b09dfb8fa85be',
  token: `${token}`,
};

const getUserDetails = async () => await axios.post(
    `${url}`,
    {},
    {
      headers,
    },
  );

const SSORedirectService = {
  getUserDetails,
};

export default SSORedirectService;
