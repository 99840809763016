import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchUserDetails } from '../../slices/ssoRedirectSlice';
import Spinner from '../Spinner';

function SSORedirect() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchUserDetails()).then(() => {
      navigate('/overview');
    });
  }, [dispatch]);

  return (<Spinner />);
}
export default SSORedirect;
