import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#002E5C',
          position: 'fixed',
        },
      },
    },
  },
});
export default Theme;
