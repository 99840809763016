import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import NavBar from '../Navigation/NavBar';
import theme from '../Navigation/Theme';

function Layout() {
  return (
    <ThemeProvider theme={theme}>
      <NavBar />
    </ThemeProvider>
  );
}
export default Layout;
