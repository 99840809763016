/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import './ProductCard.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductCards } from '../../slices/ProductCardsSlice';
import Spinner from '../Spinner';

function ProductCards(props) {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [ProductCategory, setProductCategory] = useState(props.data.id);

  const initFetch = useCallback(() => {
    dispatch(fetchProductCards(ProductCategory)).then((response) => {
      setProducts(response.payload);
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  return (
    <Box
      sx={{
        width: '100%',
        mt: 5,
        paddingTop: '30px',
        bgcolor: '#F8F9FA',
      }}
    >
      {loading ? (
        <Spinner />
      ) : (
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
          {products.map((product) => (
            <Grid item xs={6} key={product.productName}>
              <Card
                key={product.productName}
                sx={{
                  display: 'flex',
                  m: 2,
                  width: 570,
                  height: 207,
                  borderRadius: 0.5,
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    width: 210,
                    height: 207,
                    background: '#D8D8D8',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  src={product.productImage}
                  alt=""
                />
                <CardContent>
                  <Typography
                    sx={{
                      fontFamily: 'Helvetica',
                      fontWeight: 'bold',
                      fontSize: 16,
                      height: '48px',
                    }}
                    color="#002E5C"
                  >
                    {product.productName}
                  </Typography>
                  <div className="product-description">
                    <Typography sx={{ fontFamily: 'Helvetica', fontSize: 14, color: '#2D3136' }}>
                      {product.productDescription}
                    </Typography>
                  </div>
                  <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      size="small"
                      href={product.productLink}
                      target="_blank"
                      sx={{
                        fontFamily: 'Helvetica',
                        fontWeight: 'bold',
                        fontSize: 14,
                        textTransform: 'none',
                        color: '#0071CE',
                        mt: 1,
                      }}
                    >
                      Learn More
                      <NavigateNextIcon />
                    </Button>
                  </CardActions>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}

export default ProductCards;
