import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dashboardLabel: 'DASHBOARD',
  welcomeMessage: 'Welcome Back, Beatrix !',
  executiveRole: false,
};

const welcomeSlice = createSlice({
  name: 'welcome',
  initialState,
  reducers: {
    getWelcomeHeader(state, action) {
      state.push({
        ...state,
        dashboardLabel: action.payload.dashboardLabel,
        welcomeMessage: action.payload.welcomeMessage,
      });
    },
  },
});

export const { getWelcomeHeader } = welcomeSlice.actions;
export default welcomeSlice;
