import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
// import {Link} from "react-router-dom";

export const OverviewLabels = styled(Typography)(() => ({
  color: '#696F76',
  fontFamily: 'Helvetica Neue',
  fontSize: '12px',
  '&.MuiTypography-root': {
    fontFamily: 'inherit',
  },
}));

export const TaskDetails = styled(Typography)(() => ({
  color: '#002E5C',
  fontFamily: 'Helvetica',
  fontSize: '14px',
  fontWeight: 'bold',
  letterSpacing: '0',
  lineHeight: '20px',
  paddingTop: '6px',
  paddingLeft: '6px',
}));

export const TaskLink = styled(Link)(() => ({
  fontFamily: 'Helvetica',
  fontWeight: 'bold',
  fontSize: 14,
  textTransform: 'none',
  color: '#0071CE',
  // paddingLeft: '611px',
}));

export const StatLabels = styled(Typography)(() => ({
  height: '24px',
  width: '143px',
  color: '#5C666F',
  fontFamily: 'Helvetica Neue',
  fontSize: '10.6px',
  letterSpacing: 0,
  alignContent: 'center',
  lineHeight: '18px',
  '&.MuiTypography-root': {
    fontFamily: 'inherit',
  },
}));

export const LaunchLabels = styled(Typography)(() => ({
  height: '24px',
  width: '180px',
  color: '#002E5C',
  fontFamily: 'Helvetic',
  fontSize: '16px',
  fontWeight: 'bold',
  letterSpacing: '0',
  lineHeight: '20px',
  '&.MuiTypography-root': {
    fontFamily: 'inherit',
  },
}));

export const LaunchDetails = styled(Typography)(() => ({
  height: '70px',
  width: '345px',
  color: '#2D3136',
  fontFamily: 'Helvetica Neue',
  fontSize: '14px',
  letterSpacing: 0,
  lineHeight: '22px',
  '&.MuiTypography-root': {
    fontFamily: 'inherit',
  },
}));

export const LaunchLink = styled(Link)(() => ({
  color: '#0071CE',
  fontFamily: 'Helvetica',
  fontSize: '14px',
  fontWeight: 'bold',
  letterSpacing: 0,
  textTransform: 'none',
  paddingTop: '18px',
  textAlign: 'right',
  '&.MuiTypography-root': {
    fontFamily: 'inherit',
  },
}));

export const RNLLaunchLink = styled(Link)(() => ({
  // color: 'red',
  '&:hover': {
    color: '#0071CE',
    cursor: 'pointer'
  },
}));

export const PercentMain = styled(Typography)(() => ({
  height: '32px',
  width: '50px',
  color: '#002E5C',
  fontFamily: 'Helvetica',
  fontSize: '26px',
  fontWeight: 'bold',
  letterSpacing: 0,
  lineHeight: '22px',
  '&.MuiTypography-root': {
    fontFamily: 'inherit',
  },
}));

export const PercentSub = styled(Typography)(() => ({
  cursor: 'pointer',
  fontFamily: 'Helvetica',
  fontSize: '14px',
  fontWeight: 'bold',
  '&.MuiTypography-root': {
    fontFamily: 'inherit',
  },
}));

export const PlusSymbol = styled(Typography)(() => ({
  cursor: 'pointer',
  fontFamily: 'Helvetica',
  fontSize: '14px',
  fontWeight: 'bold',
}));
