import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function createData(name, search, inquiries, applicants, admits, deposits, enrolls) {
  return { name, search, inquiries, applicants, admits, deposits, enrolls };
}

const rows = [
  createData('Year-To-Date', '17,257', '1,492', 528, 376, 320, 100),
  createData('Last-Year-To-Date', '17,257', '1,492', 528, 376, 320, 100),
  createData('Last-Year-Final', '17,257', '1,492', 528, 376, 320, 100),
];

export default function MockPowerBIReport() {
  return (
    <TableContainer
      sx={{
        width: '1150px',
        width: 'auto',
        borderLeft: '1px solid #A9A9A9',
        borderTop: '1px solid #A9A9A9',
        borderRight: '1px solid #A9A9A9',
        borderBottom: '1px solid #A9A9A9',
        height: '270px',
        background: '#FFFFFF',
      }}
    >
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>
              <b>RNL INFLUENCED</b>
            </TableCell>
            <TableCell align="right">
              <b>Search</b>
            </TableCell>
            <TableCell align="right">
              <b>Inquiries</b>
            </TableCell>
            <TableCell align="right">
              <b>Applicants</b>
            </TableCell>
            <TableCell align="right">
              <b>Admits</b>
            </TableCell>
            <TableCell align="right">
              <b>Deposits</b>
            </TableCell>
            <TableCell align="right">
              <b>Enrolls</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ backgroundColor: '#FFFFFF', cursor: 'pointer' }}>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row" sx={{ borderBottom: 'none' }}>
                {row.name}
              </TableCell>
              <TableCell sx={{ borderBottom: 'none' }} align="right">
                {row.search}
              </TableCell>
              <TableCell sx={{ borderBottom: 'none' }} align="right">
                {row.inquiries}
              </TableCell>
              <TableCell sx={{ borderBottom: 'none' }} align="right">
                {row.applicants}
              </TableCell>
              <TableCell sx={{ borderBottom: 'none' }} align="right">
                {row.admits}
              </TableCell>
              <TableCell sx={{ borderBottom: 'none' }} align="right">
                {row.deposits}
              </TableCell>
              <TableCell sx={{ borderBottom: 'none' }} align="right">
                {row.enrolls}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableHead>
          <TableRow>
            <TableCell sx={{ borderBottom: 'none', borderTop: ' 1px solid gray' }}>
              <b>OVERALL</b>
            </TableCell>
            <TableCell sx={{ borderBottom: 'none', borderTop: ' 1px solid gray' }} />
            <TableCell sx={{ borderBottom: 'none', borderTop: ' 1px solid gray' }} />
            <TableCell sx={{ borderBottom: 'none', borderTop: ' 1px solid gray' }} />
            <TableCell sx={{ borderBottom: 'none', borderTop: ' 1px solid gray' }} />
            <TableCell sx={{ borderBottom: 'none', borderTop: ' 1px solid gray' }} />
            <TableCell sx={{ borderBottom: 'none', borderTop: ' 1px solid gray' }} />
          </TableRow>
        </TableHead>
        <TableBody sx={{ backgroundColor: '#FFFFFF', cursor: 'pointer' }}>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row" sx={{ borderBottom: 'none' }}>
                {row.name}
              </TableCell>
              <TableCell align="right" sx={{ borderBottom: 'none' }}>
                {row.search}
              </TableCell>
              <TableCell align="right" sx={{ borderBottom: 'none' }}>
                {row.inquiries}
              </TableCell>
              <TableCell align="right" sx={{ borderBottom: 'none' }}>
                {row.applicants}
              </TableCell>
              <TableCell align="right" sx={{ borderBottom: 'none' }}>
                {row.admits}
              </TableCell>
              <TableCell align="right" sx={{ borderBottom: 'none' }}>
                {row.deposits}
              </TableCell>
              <TableCell align="right" sx={{ borderBottom: 'none' }}>
                {row.enrolls}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
