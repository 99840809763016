import Box from '@mui/material/Box';
import Header from '../../../components/Header';
import Accordion from '../../../components/Accordion/Accordion';

function OverviewDashboard() {
  return (
    <>
      <Header />
      <Box sx={{ display: 'flex', backgroundColor: '#F8F9FA' }}></Box>
      <Accordion />
    </>
  );
}
export default OverviewDashboard;
