import { Grid, Box, Typography } from '@mui/material';

import NumberConversion from '../../utils/NumberConversion';
import './Funnel.css';

const funnelDataDynamic = [
  { stage: 'Deposits', value: 23277 },
  { stage: 'Admits', value: 203029 },
  { stage: 'Inquiries', value: 8644026 },
  { stage: 'Enroll', value: 11716 },
  { stage: 'Applicants', value: 344940 },
  { stage: 'Search', value: 151687853 },
];

const funnelDataStatic = {
  Deposits: 23277,
  Admits: 203029,
  Inquiries: 8644026,
  Enrolls: 11716,
  Applicants: 344940,
  Search: 151687853,
};

const Funnel = ({ data }) => {
  return (
    <Box sx={{ margin: '30px' }}>
      <Grid container direction="row" spacing={4}>
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography sx={{ fontSize: '12px', marginLeft: '8px', marginTop: '14px' }}>
                {' '}
                SEARCH
              </Typography>
            </Grid>
            <Grid item>
              {' '}
              <Typography sx={{ fontSize: '12px', marginLeft: '7px', marginTop: '30px' }}>
                INQUIRIES
              </Typography>
            </Grid>
            <Grid item>
              {' '}
              <Typography sx={{ fontSize: '12px', marginLeft: '7px', marginTop: '30px' }}>
                APPLICANTS
              </Typography>
            </Grid>
            <Grid item>
              {' '}
              <Typography sx={{ fontSize: '12px', marginLeft: '7px', marginTop: '30px' }}>
                ADMITS
              </Typography>
            </Grid>
            <Grid item>
              {' '}
              <Typography sx={{ fontSize: '12px', marginLeft: '7px', marginTop: '30px' }}>
                DEPOSITS
              </Typography>
            </Grid>
            <Grid item>
              {' '}
              <Typography sx={{ fontSize: '12px', marginLeft: '7px', marginTop: '30px' }}>
                ENROLLS
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <div className="rectangle1">
            <Typography
              sx={{
                color: '#FFFFFF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '7px',
              }}
            >
              {NumberConversion(data?.Prospect).toString()}
            </Typography>
          </div>
          <br />
          <div className="rectangle2">
            {' '}
            <Typography
              sx={{
                color: '#FFFFFF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '7px',
              }}
            >
              {NumberConversion(data?.Inquire).toString()}
            </Typography>
          </div>
          <br />
          <div className="rectangle3">
            {' '}
            <Typography
              sx={{
                color: '#FFFFFF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '7px',
              }}
            >
              {NumberConversion(data?.Applied).toString()}
            </Typography>
          </div>
          <br />
          <div className="rectangle4">
            {' '}
            <Typography
              sx={{
                color: '#FFFFFF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '7px',
              }}
            >
              {NumberConversion(data?.Admit).toString()}
            </Typography>
          </div>
          <br />
          <div className="rectangle5">
            {' '}
            <Typography
              sx={{
                color: '#FFFFFF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '7px',
              }}
            >
              {NumberConversion(data?.Deposit).toString()}
            </Typography>
          </div>
          <br />
          <div className="rectangle6">
            {' '}
            <Typography
              sx={{
                color: '#FFFFFF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '7px',
              }}
            >
              {NumberConversion(data?.Enroll).toString()}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Funnel;
