import HomeIcon from '@mui/icons-material/Home';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { DashboardLabel, LastUpdatedTimeStamp, WelcomeMessage } from './WelcomeStyles';

function Welcome() {
  const { instance } = useMsal();
      let activeAccount;
      let userName;

      if (instance) {
          activeAccount = instance.getActiveAccount();
          userName = activeAccount.idTokenClaims.name
      }

  const userData = useSelector((state) => state.ssoRedirect.data);
  const name = userName || 'Test User';
  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
  const curTime = `${current.getHours()}:${current.getMinutes()}`;
  const welcomeData = useSelector((state) => state.welcome);

  return (
    <>
      <Grid container sx={{ width: 'auto', mt: 4, alignItem: 'center' }}>
        <Grid item sx={{ paddingLeft: '20px', paddingRight: '20px', marginTop: '13px' }}>
          <HomeIcon sx={{ fontSize: 21, mt: -1, color: '#0F6DBE' }} />
        </Grid>
        <Divider
          orientation="vertical"
          sx={{
            height: 13,
            border: 0.1,
            color: '#696F76',
            mt: 1.7,
          }}
        />
        <Grid item>
          <DashboardLabel>{welcomeData.dashboardLabel}</DashboardLabel>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <WelcomeMessage>Welcome back {name}</WelcomeMessage>
        </Grid>
        {/* <Grid item sx={{ mt: 1.7, ml: 1 }}>
          <LastUpdatedTimeStamp>{`Last Updated ${date} at ${curTime}`}</LastUpdatedTimeStamp>
        </Grid> */}
      </Grid>
    </>
  );
}
export default Welcome;
