import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ListItem, ListItemText, Divider } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useNavigate } from 'react-router-dom';
import { AllTaskLabel, TaskHeaderLabel } from './SmartView/SmartViewStyles';
import PowerBIContainer from '../components/Reports/powerbi/PowerBIContainer';
import { fetchSmartViewConfig } from '../slices/smartViewSlice';

function PowerBIReport() {
  const initialData = useSelector((state) => state.powerBI);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBack = () => {
    navigate('/smartview');
  };

  return (
    <Box
      sx={{
        paddingBottom: '20px',
        marginTop: '20px',
        height: 'auto',
        //width: '1195px',
        backgroundColor: '#F8F9FA',
      }}
    >
      <Grid
        container
        sx={{
          width: 'auto',
          alignItem: 'center',
          backgroundColor: '#FFFFFF',
          paddingTop: '18px',
        }}
      >
        <Grid item>
          <ListItem>
            <ListItemText sx={{ color: '#0F6DBE', paddingLeft: '5px' }}>
              <HomeIcon sx={{ fontSize: 21, mt: -1 }} />
            </ListItemText>
          </ListItem>
        </Grid>
        <Divider
          orientation="vertical"
          sx={{
            height: 13,
            border: 0.1,
            color: '#696F76',
            mt: 1.7,
          }}
        />
        <Grid item>
          <ListItem>
            <ListItemText>
              <TaskHeaderLabel>{initialData.powerBIReportLabel}</TaskHeaderLabel>
            </ListItemText>
          </ListItem>
        </Grid>

        <Grid item>
          <ListItem>
            <ListItemText>
              <ArrowLeftIcon
                sx={{
                  position: 'absolute',
                  top: '21px',
                  left: '886px',
                  color: '#696F76',
                  cursor: 'pointer',
                }}
              />
              <TaskHeaderLabel
                sx={{
                  position: 'absolute',
                  top: '25px',
                  left: '904px',
                  color: '#696F76',
                  fontSize: '16px',
                  cursor: 'pointer',
                }}
                onClick={handleBack}
              >
                <b>{initialData.back}</b>
              </TaskHeaderLabel>
            </ListItemText>
          </ListItem>
        </Grid>
        <Grid item />
      </Grid>

      <Grid
        container
        direction="row"
        sx={{ paddingLeft: '25px', backgroundColor: '#FFFFFF', paddingBottom: '10px' }}
      >
        <Grid item display="flex" justifyContent="flex-start" alignItems="center">
          <AllTaskLabel>{initialData.powerBIReportHeader}</AllTaskLabel>
        </Grid>
      </Grid>
      <Divider
        sx={{
          borderLeft: 0,
          borderTop: 0,
          borderRight: 0,
          borderBotton: '1px solid #696F76',
        }}
      />
      <PowerBIContainer />
    </Box>
  );
}

export default PowerBIReport;
