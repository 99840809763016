import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SubscribedProductService from '../services/SubscribedProductService';

const initialState = {
  loading: false,
  data: [],
};

export const fetchSubscribedProduct = createAsyncThunk(
  'subscribedProducts/SubscribedProductList',
  async (email) => {
    const response = await SubscribedProductService.getSubscribedProducts(email);
    return response;
  },
);

const subscribedProductSlice = createSlice({
  name: 'subscribedProducts',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSubscribedProduct.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchSubscribedProduct.pending]: (state) => {
      state.loading = true;
    },
  },
});

export const subscribedProductSelector = (state) => state;
export default subscribedProductSlice;
