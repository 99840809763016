import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ListItem, ListItemText, Divider } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { AllTaskLabel, TaskHeaderLabel } from './SmartViewStyles';
import SmartViewTable from './SmartViewTable';

function SmartView() {
  const initialData = useSelector((state) => state.smartView);
  return (
    <Box
      sx={{
        paddingBottom: '20px',
        marginTop: '20px',
        height: 'auto',
        width: '1195px',
        //width: 'auto',
        backgroundColor: '#F8F9FA',
      }}
    >
      <Grid
        container
        sx={{
          width: 'auto',
          alignItem: 'center',
          backgroundColor: '#FFFFFF',
          paddingTop: '18px',
        }}
      >
        <Grid item>
          <ListItem>
            <ListItemText sx={{ color: '#0F6DBE', paddingLeft: '5px' }}>
              <HomeIcon sx={{ fontSize: 21, mt: -1 }} />
            </ListItemText>
          </ListItem>
        </Grid>
        <Divider
          orientation="vertical"
          sx={{
            height: 13,
            border: 0.1,
            color: '#696F76',
            mt: 1.7,
          }}
        />
        <Grid item>
          <ListItem>
            <ListItemText>
              <TaskHeaderLabel>{initialData.smartViewLabel}</TaskHeaderLabel>
            </ListItemText>
          </ListItem>
        </Grid>
        <Grid item />
      </Grid>
      <Grid
        container
        direction="row"
        sx={{ paddingLeft: '25px', backgroundColor: '#FFFFFF', paddingBottom: '10px' }}
      >
        <Grid item display="flex" justifyContent="flex-start" alignItems="center">
          <AllTaskLabel>{initialData.smartViewHeader}</AllTaskLabel>
        </Grid>
      </Grid>
      <Divider
        sx={{
          borderLeft: 0,
          borderTop: 0,
          borderRight: 0,
          borderBotton: '1px solid #696F76',
        }}
      />
      <SmartViewTable />
    </Box>
  );
}

export default SmartView;
