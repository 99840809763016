/* eslint-disable class-methods-use-this */
/* eslint-disable default-param-last */
import axios from 'axios';
import { extend, map } from 'lodash';

class Axios {
  constructor(uribase, defaultheaders) {
    this.base = uribase;
    this.headers = defaultheaders;
  }

  get = (uri, query, headers) => {
    const rq = this._prepareRequest('GET', uri, query, headers, false);
    const result = this._call(rq);
    return result;
  };

  post = (uri, body, query, headers) => {
    const rq = this._prepareRequest('POST', uri, query, headers, body);
    return this._call(rq);
  };

  put = (uri, body, query, headers) => {
    const rq = this._prepareRequest('PUT', uri, query, headers, body);
    return this._call(rq);
  };

  patch = (uri, body, query, headers) => {
    const rq = this._prepareRequest('PATCH', uri, query, headers, body);
    return this._call(rq);
  };

  delete = (uri, query, headers) => {
    const rq = this._prepareRequest('DELETE', uri, query, headers, false);
    return this._call(rq);
  };

  _prepareRequest = (method, uri = {}, query = {}, heads = {}, data) => {
    const baseURL = this.base;
    const url = this._getURL(uri, query);
    const headers = this._getHeaders(heads);
    let requestObj = {
      method,
      baseURL,
      url,
      headers,
    };
    if (
      (method === 'POST' || method === 'PUT') &&
      (headers['Content-Type'] === 'application/json' || headers['Content-Type'] === undefined)
    ) {
      const body = { data: JSON.stringify(data) };
      requestObj = { ...requestObj, ...body };
    }
    return requestObj;
  };

  _getHeaders = (headers) => extend(this.headers, headers);

  _getURL = (uri, query) => `${uri}?${this._getQueryString(query)}`;

  // eslint-disable-next-line arrow-body-style
  _getQueryString = (options) => {
    return map(options, (value, key) => `${key}=${encodeURIComponent(value)}`).join('&');
  };

  _call = async (rq) => {
    try {
      const result = await axios(rq);
      return result.data;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log('Error', error.message);
      }
      return error.response;
    }
  };
}

const BASE_URL = process.env.REACT_APP_PORTAL_BASE_URL;

const DEFAULT_HEADER = {
  'Content-Type': 'application/json',
  'Ocp-Apim-Subscription-Key': process.env.REACT_APP_AZURE_SUBSCRIPTION_KEY,
  'Ocp-Apim-Trace': process.env.REACT_APP_AZURE_API_TRACE,
};

export default new Axios(BASE_URL, DEFAULT_HEADER);
