/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable radix */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import { Grid,Typography } from '@mui/material';
import './PersonalizeDialog.css';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useMsal } from '@azure/msal-react';
import rnlRow from '../../../assets/Images/RNL_gray_logo.png';
import {
  fetchSubscribedProduct
} from '../../../slices/subscribedProductSlice';
import {
  PersonalizeDialogTitle,
  PersonalizeDialogSubTitle,
} from './PersonalizeDialogStyles';
import { getAlert } from '../../../slices/GenericAlertSlice';

function PersonalizeDialog(props) {
  const subscribedProducts = useSelector((state) => state.subscribedProducts);
  const { instance } = useMsal();
  let activeAccount;
  let userName;
  let email;

  if (instance) {
    activeAccount = instance.getActiveAccount();
    userName = activeAccount.idTokenClaims.name;
    email = activeAccount.idTokenClaims.tableau_email;
  }

  const name = userName || 'Sunil Hirole';
  const dispatch = useDispatch();
  const products = subscribedProducts.data ? subscribedProducts.data : [];

  const initFetch = useCallback(() => {
    dispatch(fetchSubscribedProduct(email));
  }, [dispatch]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  const handleDialogClose = () => {
    props.setOpenDialog(false);
    dispatch(getAlert({ openAlert: false }));
  };

  return (
    <Dialog
      open={props.openDialog}
      PaperProps={{
        sx: {
          width: '956px',
          height: 'auto',
          maxWidth: '850px',
          overflowX: 'auto',
        },
      }}
    >
      <IconButton
        onClick={handleDialogClose}
        sx={{ position: 'absolute', right: 8, top: 8, color: '#838A91' }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ paddingTop: '70px', paddingBottom: '50px' }}>
        <div className="imgAlign">
          <ViewQuiltIcon sx={{ height: '47.95px', width: '47.92px', color: '#D9D8D8' }} />
        </div>
        <PersonalizeDialogTitle>
          Welcome {name} Help us personalize your experience
        </PersonalizeDialogTitle>
        <PersonalizeDialogSubTitle sx={{ paddingBottom: '10px' }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a enim quis massa lobortis
          luctus. Phasellus eget odio felis. Donec et lacus vitae lacus.
        </PersonalizeDialogSubTitle>
        <PersonalizeDialogSubTitle
          sx={{ fontSize: '11px', paddingBottom: '10px', fontFamily: 'Helvetica Neue' }}
        >
          CURRENTLY SUBSCRIBED
        </PersonalizeDialogSubTitle>
        {/* <div className="imgAlign">

        </div> */}
        <Grid container direction="row" justifyContent="flex-start" sx={{ paddingLeft: '216px' }}>
          {products && products.map((product) => (
            <Grid item sx={{ paddingLeft: '80px', fontSize: '12px' }} key={product.id}>
              <img className="imgAlign" src={rnlRow} alt="" height="45px" />

              <Typography sx={{
                width: 10, fontSize: '14px'
              }}>{product.productName}</Typography>

            </Grid>
          ))}
        </Grid>
      </Box>
    </Dialog>
  );
}
export default PersonalizeDialog;
