import { configureStore } from '@reduxjs/toolkit';
import welcomeSlice from '../slices/welcomeSlice';
import productCards from '../slices/ProductCardsSlice';
import productTypeSlice from '../slices/productTypeSlice';
import powerBISlice from '../slices/powerBISlice';
import smartViewSlice from '../slices/smartViewSlice';
import genericAlertReducer from '../slices/GenericAlertSlice';
import engagementScoreReducer from '../slices/engagementScoreSlice';
import demandBuilderReducer from '../slices/demandBuilderSlice';
import ssoRedirectReducer from '../slices/ssoRedirectSlice';
import powerBIExtIntReportReduce from '../slices/powerBIExtIntSlice';
import forecastPlusReducer from '../slices/forecastPlusSlice';
import kpiReducer from '../slices/kpiSlice';
import subscribedProductSlice from '../slices/subscribedProductSlice';

const store = configureStore({
  reducer: {
    welcome: welcomeSlice.reducer,
    productCards: productCards.reducer,
    productType: productTypeSlice.reducer,
    powerBI: powerBISlice.reducer,
    smartView: smartViewSlice.reducer,
    genericAlert: genericAlertReducer,
    engagementScoreKpi: engagementScoreReducer,
    demandBuilderKpi: demandBuilderReducer,
    ssoRedirect: ssoRedirectReducer,
    powerBIExtInt: powerBIExtIntReportReduce,
    forecastPlus: forecastPlusReducer,
    kpiDetails: kpiReducer,
    subscribedProducts: subscribedProductSlice.reducer,
  },
});

export default store;
