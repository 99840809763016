//File not is use
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SmartViewConfigService from '../services/SmartViewConfigService';

const initialState = {
  smartViewLabel: 'SMARTVIEW',
  smartViewHeader: 'SmartView',
  data: [],
  selectedCell: {},
  groupId: '',
  reportId: '',
  childReportName: '',
  loading: false,
};

export const fetchSmartViewConfig = createAsyncThunk(
  'smartViewConfig/SmartViewConfig',
  async () => {
    const response = await SmartViewConfigService.getSmartViewConfig();
    return response;
  },
);

const smartViewSlice = createSlice({
  name: 'smartView',
  initialState,
  reducers: {
    showSmartViewHeader(state, action) {
      state.push({
        ...state,
        smartViewLabel: action.payload.smartViewLabel,
        smartViewHeader: action.payload.smartViewHeader,
      });
    },
    saveSelectedReport(state, action) {
      state.selectedCell = action.payload;
    },
  },
  extraReducers: {
    [fetchSmartViewConfig.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchSmartViewConfig.pending]: (state) => {
      state.loading = true;
    },
  },
});

export const { showSmartViewHeader, selectedRow, saveSelectedReport } = smartViewSlice.actions;
export default smartViewSlice;
