import api from '../utils/axios';
import { GET_EXT_INT_REPORTS } from '../utils/Constants';
import { PERSONALIZATION } from '../utils/Labels';

const getExtIntReports = (email) => api.get(GET_EXT_INT_REPORTS, {}, { email });

const powerBIExtIntReportService = {
  getExtIntReports,
};
export default powerBIExtIntReportService;
