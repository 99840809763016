import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

export const Cell = styled(TableCell)(() => ({
  cursor:'pointer',
  '&:hover': {
    color: '#0476D0'
  },
}));

export const KeyLabel = styled(Typography)(() => ({
  color: '#002E5C',
  fontFamily: 'Helvetica',
  fontSize: '16px',
  fontWeight: 'bold',
  letterSpacing: '0',
  paddingBottom: '10px',
}));

export const AllTaskLabel = styled(Typography)(() => ({
  height: '31px',
  width: '343px',
  color: '#002E5C',
  fontFamily: 'Helvetica',
  fontSize: '24px',
  fontWeight: 'bold',
  letterSpacing: 0,
  lineHeight: '20px',
}));

export const TaskHeaderLabel = styled(Typography)(() => ({
  height: '24px',
  width: '136px',
  color: '#696F76',
  fontFamily: 'Helvetica Neue Regular',
  fontSize: '14px',
  letterSpacing: '0.25px',
  lineHeight: '16px',
  '&.MuiTypography-root': {
    fontFamily: 'inherit',
  },
}));
