import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PowerBIService from '../services/PowerBIService';

const initialState = {
  powerBIReportLabel: 'SMARTVIEW',
  powerBIReportHeader: 'SmartView',
  back: 'Back',
  loading: false,
  reportId: '',
  embedURL: '',
  embeddedToken: '',
  error: '',
  data: '',
};

export const fetchPowerBIEmbeddedConfig = createAsyncThunk(
  'powerBI/PowerBIEmbeddedConfig',
  async (body) => {
    const response = await PowerBIService.getPowerBIEmbeddedConfig(body);
    return response;
  },
);

const powerBISlice = createSlice({
  name: 'powerBI',
  initialState,
  reducers: {
    getPowerBIEmbeddedConfig(state, action) {
      state.push({
        ...state,
        reportId: action.payload.reportId,
        embedURL: action.payload.embedURL,
        embeddedToken: action.payload.embeddedToken,
      });
    },
  },
  extraReducers: {
    [fetchPowerBIEmbeddedConfig.fulfilled]: (state, action) => {
      state.reportId = action.payload.reportId;
      state.embeddedToken = action.payload.embeddedToken;
      state.embedURL = action.payload.embedURL;
      state.loading = false;
      state.data = action.payload;
    },
    [fetchPowerBIEmbeddedConfig.pending]: (state) => {
      state.loading = true;
    },
    [fetchPowerBIEmbeddedConfig.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Error, something went wrong. Contact support if problem persist';
    },
  },
});

export const powerBIConfigSelector = (state) => state;
export const { getPowerBIEmbeddedConfig } = powerBISlice.actions;
export default powerBISlice;
