/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import productCardsService from '../services/ProductCardsService';

const initialState = {
  loading: false,
  productCards: [],
};

export const fetchProductCards = createAsyncThunk(
  'productCards/productCardsList',
  async (ProductCategory) => {
    const response = await productCardsService.getProductCards(ProductCategory);
    return response;
  },
);

const productCardsSlice = createSlice({
  name: 'productCards',
  initialState,
  extraReducer: {
    [fetchProductCards.fulfilled]: (state, action) => {
      state.productCards = action.payload;
      console.log('state.productCards', state.productCards);
      state.loading = false;
      state.query = action.payload;
    },
    [fetchProductCards.pending]: (state) => {
      state.loading = true;
    },
  },
});

export const productCardsSelector = (state) => state.productCards;
export default productCardsSlice;
