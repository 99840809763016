import api from '../utils/axios';
import { DEV_SUBSCRIBE_PRODUCT } from '../utils/Constants';

// eslint-disable-next-line max-len
const getSubscribedProducts = (email) => api.get(DEV_SUBSCRIBE_PRODUCT, {}, { email });

const SubscribedProductService = {
  getSubscribedProducts,
};
export default SubscribedProductService;
