/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PsychologyIcon from '@mui/icons-material/Psychology';
import InfoIcon from '@mui/icons-material/Info';
import PeopleIcon from '@mui/icons-material/People';
import CachedIcon from '@mui/icons-material/Cached';
import SchoolIcon from '@mui/icons-material/School';

import AppsIcon from '@mui/icons-material/Apps';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ListItemText from '@mui/material/ListItemText';
import PersonalizeDialog from '../PersonalizeDialog';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { HEADER } from '../../../utils/Labels';
import { loginRequest, b2cPolicies } from '../../../authConfig';

import './NavBar.css';
import Footer from '../../Footer';
import rnlLogo from '../../../assets/Images/RNL_gray_logo.png';
import rnlSmallLogo from '../../../assets/Images/RNL.png';
import {
  KNOWLEDGE_CENTER_URL,
  COLLABORATION_CENTER_URL,
  SYNC_CENTER_URL,
} from '../../../utils/Constants';

const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Header() {
  const isExecutive = useSelector((state) => state.welcome.executiveRole);
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openNotification, setOpenNotification] = React.useState(false);

  const handleDrawerToggle = () => {
    setOpen((o) => !o);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleNotification = () => {
    setOpenNotification(true);
  };

  const navigate = useNavigate();

  const handleSmartView = () => {
    navigate('/smartview');
  };

  const [drawerListItem, setDrawerListItem] = React.useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const initialData = useSelector((state) => state.notifications);
  const changeCss = (e) => {
    const drawerItem = [false, false, false, false, false, false];
    e.preventDefault();
    e.stopPropagation();
    const selectedItem = parseInt(e.currentTarget.getAttribute('data-index'));
    let i;

    for (i = 0; i < drawerItem.length; i++) {
      if (i == selectedItem) {
        drawerItem[i] = true;
      } else {
        drawerItem[i] = false;
      }
    }
    setDrawerListItem(drawerItem);
    navigate('/overview');
  };
  const enableSmartViewScreen = (e) => {
    const drawerItem = [false, false, false, false, false, false];
    e.preventDefault();
    e.stopPropagation();
    const selectedItem = parseInt(e.currentTarget.getAttribute('data-index'));
    let i;

    for (i = 0; i < drawerItem.length; i++) {
      if (i == selectedItem) {
        drawerItem[i] = true;
      } else {
        drawerItem[i] = false;
      }
    }
    setDrawerListItem(drawerItem);
    navigate('/smartview');
  };

  const rnlProducts = (e) => {
    const drawerItem = [false, false, false, false, false, false, false];
    e.preventDefault();
    e.stopPropagation();
    const selectedItem = parseInt(e.currentTarget.getAttribute('data-index'));
    let i;

    for (i = 0; i < drawerItem.length; i++) {
      if (i == selectedItem) {
        drawerItem[i] = true;
      } else {
        drawerItem[i] = false;
      }
    }
    setDrawerListItem(drawerItem);
    navigate('/enrollment');
  };

  const drawerItemCSS = {
    back: {
      height: '55px',
      width: '285px',
      backgroundColor: '#0071CE',
      cursor: 'pointer',
    },
    front: {
      color: '#FFFFFF',
      fontFamily: 'Helvetica Neue',
      fontSize: '19px',
      fontWeight: 450,
      letterSpacing: 0,
      cursor: 'pointer',
    },
  };

  const { instance, inProgress, accounts } = useMsal();
  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => {
      console.log('====ERRORORRR====', error);
    });
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect();
  };

  const isAuthenticated = useIsAuthenticated();
  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    setTimeout(() => {
      if (accounts.length === 0) {
        instance.loginRedirect();
      }
    }, 500);
  }

  return (
    <div>
      <AuthenticatedTemplate>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar position="fixed" open={open} sx={{ background: '#002E5C' }}>
            <Toolbar variant="dense">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
                sx={{
                  marginRight: 1.5,
                  width: 25,
                  Height: 20,
                }}
              >
                <MenuIcon className="menu" sx={{ width: 27, height: 27 }} />
              </IconButton>
              <Typography noWrap component="div" className="rnl" sx={{ height: 25, fontSize: 18 }}>
                {HEADER.TITLE}
              </Typography>
              {/* <IconButton
                className="shape-1"
                onClick={handleOpenDialog}
                type="submit"
                sx={{ color: '#FFF' }}
              >
                <AppsIcon />
              </IconButton> */}

              {/* <PersonalizeDialog openDialog={openDialog} setOpenDialog={setOpenDialog} /> // Descoped */}
              <IconButton
                className="shape-1"
                onClick={handleLogoutRedirect}
                sx={{ height: 25, fontSize: 18, color: '#FFF' }}
              >
                Logout
              </IconButton>
              {/* <UnauthenticatedTemplate>
            <IconButton className="shape-1" onClick={handleLoginRedirect} sx={{ color: '#FFF' }}>
                LOGIN
              </IconButton>
            </UnauthenticatedTemplate> */}

              {/* <IconButton className="shape-1" sx={{ color: '#FFF' }}> */}
              {/* <PersonIcon /> */}
              {/* </IconButton> */}
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <List
              sx={{
                mt: 6,
                border: 2,
                borderColor: '#EBECED',
                width: 250,
                height: 600,
              }}
            >
              <Grid
                container
                sx={{ cursor: 'pointer' }}
                data-index="0"
                onClick={changeCss}
                style={drawerListItem[0] ? drawerItemCSS.back : null}
              >
                <Grid item>
                  <ListItem>
                    <ListItemText>
                      <DashboardIcon
                        style={drawerListItem[0] ? drawerItemCSS.front : null}
                        sx={{ color: '#89949E', width: 25, height: 25 }}
                      />
                    </ListItemText>
                  </ListItem>
                </Grid>
                <Grid item>
                  <ListItem sx={{ fontFamily: 'Helvetica Neue Bold' }}>
                    <Typography
                      style={drawerListItem[0] ? drawerItemCSS.front : null}
                      sx={{
                        color: '#2D3136',
                        fontFamily: 'Helvetica Neue Bold',
                        fontSize: '18px',
                        mt: 0.5,
                      }}
                    >
                      {HEADER.DASHBOARD}
                    </Typography>
                  </ListItem>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{ cursor: 'pointer' }}
                data-index="1"
                onClick={() => window.open(SYNC_CENTER_URL, '_blank')}
                style={drawerListItem[1] ? drawerItemCSS.back : null}
              >
                <Grid item>
                  <ListItem>
                    <ListItemText>
                      <CachedIcon
                        style={drawerListItem[1] ? drawerItemCSS.front : null}
                        sx={{ color: '#89949E', width: 30, height: 30 }}
                      />
                    </ListItemText>
                  </ListItem>
                </Grid>
                <Grid item>
                  <ListItem>
                    <ListItemText
                      style={drawerListItem[1] ? drawerItemCSS.front : null}
                      sx={{ color: '#2D3136', ml: -1, mt: 1 }}
                    >
                      {HEADER.SYNC_CENTER}
                    </ListItemText>
                  </ListItem>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{ cursor: 'pointer' }}
                data-index="2"
                onClick={enableSmartViewScreen}
                style={drawerListItem[2] ? drawerItemCSS.back : null}
              >
                <Grid item>
                  <ListItem>
                    <ListItemText>
                      <PsychologyIcon
                        style={drawerListItem[2] ? drawerItemCSS.front : null}
                        sx={{ color: '#89949E', width: 30, height: 30 }}
                      />
                    </ListItemText>
                  </ListItem>
                </Grid>
                <Grid item>
                  <ListItem>
                    <ListItemText
                      style={drawerListItem[2] ? drawerItemCSS.front : null}
                      sx={{ color: '#2D3136', ml: -1, mt: 1 }}
                    >
                      {HEADER.SMART_VIEW}
                    </ListItemText>
                  </ListItem>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ cursor: 'pointer' }}
                data-index="3"
                onClick={() => window.open(KNOWLEDGE_CENTER_URL, '_blank')}
                style={drawerListItem[3] ? drawerItemCSS.back : null}
              >
                <Grid item>
                  <ListItem>
                    <ListItemText>
                      <SchoolIcon
                        style={drawerListItem[3] ? drawerItemCSS.front : null}
                        sx={{ color: '#89949E', width: 30, height: 30 }}
                      />
                    </ListItemText>
                  </ListItem>
                </Grid>
                <Grid item>
                  <ListItem>
                    <ListItemText
                      style={drawerListItem[3] ? drawerItemCSS.front : null}
                      sx={{ color: '#2D3136', ml: -1, mt: 1 }}
                    >
                      {HEADER.KNOWLEDGE_CENTER}
                    </ListItemText>
                  </ListItem>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ cursor: 'pointer' }}
                data-index="5"
                onClick={() => window.open(COLLABORATION_CENTER_URL, '_blank')}
                style={drawerListItem[5] ? drawerItemCSS.back : null}
              >
                <Grid item>
                  <ListItem>
                    <ListItemText>
                      <PeopleIcon
                        style={drawerListItem[5] ? drawerItemCSS.front : null}
                        sx={{ color: '#89949E', width: 30, height: 30 }}
                      />
                    </ListItemText>
                  </ListItem>
                </Grid>
                <Grid item>
                  <ListItem>
                    <ListItemText
                      style={drawerListItem[5] ? drawerItemCSS.front : null}
                      sx={{ color: '#2D3136', ml: -1, mt: 1 }}
                    >
                      {HEADER.COLLABORATION_CENTER}
                    </ListItemText>
                  </ListItem>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ cursor: 'pointer' }}
                data-index="6"
                onClick={rnlProducts}
                style={drawerListItem[6] ? drawerItemCSS.back : null}
              >
                <Grid item>
                  <ListItem sx={{ paddingLeft: '4px' }}>
                    <ListItemText>
                      <img
                        src={rnlSmallLogo}
                        alt=""
                        style={{
                          width: '40px',
                          height: '35px',
                          marginLeft: '5px',
                          filter: 'grayscale(100%)',
                        }}
                      />
                    </ListItemText>
                  </ListItem>
                </Grid>
                <Grid item>
                  <ListItem>
                    <ListItemText
                      style={drawerListItem[6] ? drawerItemCSS.front : null}
                      sx={{ color: '#2D3136', ml: -2, mt: 4 }}
                    />
                    RNL Product Center
                  </ListItem>
                </Grid>
              </Grid>
            </List>
          </Drawer>
          <Box component="main" sx={{ mt: 2.5 }}>
            <Outlet />
            <Footer />
          </Box>
        </Box>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar position="fixed" open={open} sx={{ background: '#002E5C' }}>
            <Toolbar variant="dense">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
                sx={{
                  marginRight: 1.5,
                  width: 25,
                  Height: 20,
                }}
              >
                <MenuIcon className="menu" sx={{ width: 27, height: 27 }} />
              </IconButton>
              <Typography noWrap component="div" className="rnl" sx={{ height: 25, fontSize: 18 }}>
                {HEADER.TITLE}
              </Typography>
              <IconButton
                className="shape-1"
                onClick={handleLoginRedirect}
                sx={{ height: 25, fontSize: 18, color: '#FFF' }}
              >
                LOGIN
              </IconButton>
            </Toolbar>
          </AppBar>
        </Box>
      </UnauthenticatedTemplate>
    </div>
  );
}
