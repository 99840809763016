/* eslint-disable max-len */
/* eslint-disable camelcase */
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Grid';
import Carousel from '../components/Carousel';
import ProductCards from '../components/ProductCards';
import Dropdown from '../components/Dropdown';
import Welcome from '../components/Welcome';
import AdvFinAidBanner from '../assets/Images/RNL CP Banner FinAid 1173x250.png';

const productCardData = {
  id: 'AdvancedFinaid',
};

function AdvancedFinaid() {
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Welcome />
        </Grid>
        <Grid item xs={6} sx={{ mt: 5, pl: 70 }}>
          <Dropdown />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2 }} />
      {/* <Carousel /> */}
      <img src={AdvFinAidBanner} alt="enrollment image" style={{ width: '1197px' }} />
      <ProductCards data={productCardData} />
    </>
  );
}
export default AdvancedFinaid;
