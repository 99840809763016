// url
export const GET_PRODUCT_CARDS = '/service/ProductCategory';
export const DEV_SMARTVIEW_CONFIG = '/service/GetSmartViewData';
export const TEST_SMARTVIEW_CONFIG = '/service/PowerBITestFun';
export const DEV_FETCH_EMBED_TOKEN_AND_URL = '/service/PowerBIReportByGroupID';
export const GET_ENGAGEMENT_SCORES = '/service/GetEngagementScore';
export const GET_DEMAND_BUILDER = '/service/GetDemandBuilder';
export const GET_EXT_INT_REPORTS = 'service/GetInternalPortalReports';
// export const GET_EXT_INT_REPORTS = 'service/PowerBITestFun';
export const GET_FORECAST_SCORE = 'service/GetForecastScore';
export const GET_KPI_DETAILS = 'services/getKPIDetails';
export const DEV_SUBSCRIBE_PRODUCT = '/service/getSubscribedProductByUser';

export const KNOWLEDGE_CENTER_URL = process.env.REACT_APP_KNOWLEDGE_CENTER_URL
export const COLLABORATION_CENTER_URL = process.env.REACT_APP_COLLABORATION_CENTER_URL
export const SYNC_CENTER_URL = process.env.REACT_APP_SYNC_CENTER_URL
