import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import powerBIExtIntReportService from '../services/powerBIExtIntReportService';

const initialState = {
  data: {},
  selectedCell: {},
  loading: false,
  error: '',
};

export const fetchPowerBIExtIntReport = createAsyncThunk(
  'powerBIExtInt/fetchPowerBIExtIntReport',
  async (email) => {
    const response = await powerBIExtIntReportService.getExtIntReports(email);
    return response;
  },
);

const powerBIExtIntSlice = createSlice({
  name: 'powerBIExtInt',
  initialState,
  reducers: {
    showSmartViewHeader(state, action) {
      state.push({
        ...state,
        smartViewLabel: action.payload.smartViewLabel,
        smartViewHeader: action.payload.smartViewHeader,
      });
    },
    saveSelectedReport(state, action) {
      state.selectedCell = action.payload;
    },
  },
  extraReducers: {
    [fetchPowerBIExtIntReport.pending]: (state) => {
      state.loading = true;
    },
    [fetchPowerBIExtIntReport.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchPowerBIExtIntReport.rejected]: (state) => {
      state.loading = false;
      state.error = 'Error';
    },
  },
});
export const { showSmartViewHeader, selectedRow, saveSelectedReport } = powerBIExtIntSlice.actions;
export default powerBIExtIntSlice.reducer;
